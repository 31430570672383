export default {
  init(view) {
    view.params[this.name].buttons = view.page.buttons.map(button => {
      return {
        ...button,
        onClick: () => {
          this.handles(view).onClick(button)
        }
      }
    })
  },

  handles(view) {
    const functionalities = {
      dialog: button => {
        view.dialog({
          type: button.type,
          hasIcon: true,
          title: button.name,
          message: view.$t(button.props.dialogMessage),
          onCancel: () => {},
          onConfirm: () => {
            view.isLoading.list = true
            view.api({
              module: this,
              endPoint: button.props.submit,
              method: 'post',
              body: {
                ...view.model[this.name]
              },
              callback: res => {
                view.isLoading.list = false
                if (
                  typeof res.file_name !== 'undefined' &&
                  typeof res.file_content !== 'undefined'
                ) {
                  const data = Uint8Array.from(atob(res.file_content), c =>
                    c.charCodeAt(0)
                  )
                  const blob = new Blob([data], {
                    type: 'octet/stream'
                  })
                  const link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.style = 'visibility:hidden'
                  link.download = res.file_name
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)

                  view.params[button.props.moduleLink].checkedRows = []
                  return
                }

                view.toast({
                  type: res.success ? 'is-success' : 'is-danger',
                  duration: 5000,
                  message: view.$t(res.success || res.error)
                })

                if (view.model[this.name].ids) {
                  view.params[button.props.moduleLink].checkedRows = []
                }

                view.modules[button.props.moduleLink].uses.get(view)
              }
            })
          }
        })
      },
      checkedRows: button => {
        view.model[this.name].ids = view.params[
          button.props.moduleLink
        ].checkedRows.map(c => c.id)
      }
    }

    return {
      onClick: button => {
        if (button.portal_route) {
          return this.handles(view).useRouterByPortalRoute(button)
        }

        this.handles(view).callButtonFunctionalities(button)
      },

      callButtonFunctionalities(button) {
        const propsKeys = Object.keys(button.props)

        propsKeys.forEach(prop => {
          if (!Object.keys(functionalities).includes(prop)) return
          functionalities[prop](button)
        })
      },

      useRouterByPortalRoute: button => {
        if (button.portal_route === '$back') {
          return view.$router.back()
        }

        view.$router.push(button.portal_route)
      }
    }
  }
}
