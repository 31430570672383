// TODO: migrate all number format functions to this helper

const unmaskValue = (
  { delimiter, numeralDecimalMark, numeralDecimalScale },
  value
) => {
  if (value === undefined) return

  let unmaskedValue = null

  if (!numeralDecimalScale) {
    unmaskedValue = parseInt(value.replaceAll(delimiter, ''))
  } else {
    unmaskedValue = parseFloat(
      parseFloat(
        value.replaceAll(delimiter, '').replace(numeralDecimalMark, '.')
      ).toFixed(numeralDecimalScale)
    )
  }

  return unmaskedValue
}

const compareValues = (value1, operator, value2) => {
  switch (operator) {
    case '==':
    case '===':
      return Number(value1) === Number(value2)
    case '<':
      return Number(value1) < Number(value2)
    case '>':
      return Number(value1) > Number(value2)
    case '<=':
      return Number(value1) <= Number(value2)
    case '>=':
      return Number(value1) >= Number(value2)
    default:
      return false
  }
}

export { unmaskValue, compareValues }
