import omit from 'lodash/omit'
import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import actions from './actions'
import elements from './elements'
import { features } from '@/data/features'
import { useUserSettingsStore } from '@/stores/userSettings'
import { useLayoutStore } from '@/stores/layout'
import {
  downloadFileNew,
  getDocument,
  downloadFile,
  downloadFilePopup
} from '@/services/document'
import inflateForm from './inflateForm'
import forms from './forms'
import { api } from '@/services/api'
import { useListFiltersStore } from '../../stores/listFilters'
import setModelFeatureToSlug from './slugModelFeatures'

const mime = require('mime-types')

export default {
  init(view) {
    const userSettings = useUserSettingsStore()
    const layoutStore = useLayoutStore()
    let perPage = 25

    this.resetSelectedRow = layoutStore.resetSelectedRow
    this.addSelectedRow = layoutStore.addSelectedRow
    this.getSelectedRow = layoutStore.getSelectedRow

    // to review
    window.transiting = {}

    view.model[this.name].page = view.$route.query.page
      ? parseInt(view.$route.query.page)
      : 1

    view.params[this.name].checkable = !!view.modules[this.name].props.checkable

    if (typeof view.modules[this.name].props.model !== 'undefined')
      perPage = view.modules[this.name].props.model.perPage

    if (this.name === 'tabs') {
      if (
        typeof view.modules[this.name].uses.tabs[
          view.model[this.name].activeTabIndex
        ].view.props.model !== 'undefined'
      )
        perPage =
          view.modules[this.name].uses.tabs[
            view.model[this.name].activeTabIndex
          ].view.props.model.perPage
    }

    view.model[this.name].perPage =
      userSettings.getFilter(`per-page-${this.form}`) || perPage

    this.handles(view).setModelSearch()

    this.get(view)

    this.resetSelectedRow(this.form, view.$route.params.id)
  },

  get(view) {
    if (this.name === 'tabs') return // needs refactor

    const listFiltersStore = useListFiltersStore(this.form)

    const filters = listFiltersStore.getActiveFilter

    view.api({
      module: this,
      endPoint: view.modules[this.name].uses.flow,
      method: 'post',
      body: {
        ...view.model[this.name],
        id: view.$route.params.id,
        search: view.$handleQueryParams(view.model[this.name].search),
        form: this.form,
        adv_search: filters,
        abstractModel: this.abstractModel
      },
      callback: res => {
        res = res.dataset
          ? res
          : res.actions[Object.keys(res.actions)[0]].route_response.content

        view.model[this.name].model = res.form.form_model

        view.params[this.name].notifications = res.notifications

        if (view.model.bulk) view.model.bulk.model = res.form.form_model

        this.handles(view).table(res)
      }
    })
  },

  params(view) {
    return {
      checkedRows: [],
      listActions: [],
      transitionsId: null,
      checkable: false,
      selected: null,
      hiddenFields: {}
    }
  },

  handles(view) {
    return {
      setCheckedRowsCount: () => {
        if (view.model.bulk)
          view.model.bulk.checkedRows =
            view.params[this.name].checkedRows.length
      },
      pageChange: value => {
        view.model[this.name].page = value

        view.$router.push({
          name: view.$route.name,
          query: { ...view.$route.query, ...{ page: value } }
        })

        this.get(view)
      },

      sort: (col, order) => {
        view.model[this.name].sort_by = `${col}.${order}`
        this.get(view)
      },

      select: row => {
        this.addSelectedRow(row.id, this.form)
      },

      filterInput: () => {
        view.model[this.name].page = 1
        clearTimeout(this.typingTimer)

        const query = view.$handleQueryParams(view.model[this.name].search)

        this.typingTimer = setTimeout(() => {
          view.$router.push({ name: view.$route.name, query })
          // runs $route.query watcher and calls routeQueryChange
        }, 500)
      },

      routeQueryChange: (searchQuery, oldSearchQuery) => {
        if (isEqual(omit(searchQuery, 'page'), omit(oldSearchQuery, 'page')))
          return

        // parse query params
        const search = omit(
          mapValues(
            {
              ...view.model[this.name].bulkSearch,
              ...searchQuery
            },
            value =>
              moment(value, 'YYYY-MM-DD', true).isValid() ||
              moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
                ? new Date(value)
                : value
          ),
          'page'
        )

        view.model[this.name].search = search

        this.get(view)
      },

      filterInputClear: field => {
        if (!view.model[this.name].search[field.name]) return

        view.model[this.name].search[field.name] = undefined

        const query = view.$handleQueryParams(view.model[this.name].search)

        view.$router.push({ name: view.$route.name, query }).catch(() => {})
        // runs $route.query watcher and calls routeQueryChange
      },

      cleanSearch: () => {
        view.model[this.name].search =
          { ...view.model[this.name].bulkSearch } || {}

        view.$router.push({ name: view.$route.name, query: {} }).catch(() => {})
        // runs $route.query watcher and calls routeQueryChange
      },

      setModelSearch: () => {
        const query = omit(view.$route.query, 'page')
        view.model[this.name].search = {}

        // can be changed to mapValues
        Object.entries(query).forEach(([key, value]) => {
          view.model[this.name].search[key] =
            moment(value, 'YYYY-MM-DD', true).isValid() ||
            moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
              ? new Date(value)
              : value
        })
      },

      startBulkSearch: () => {
        if (Object.keys(view.$route.query).length > 0)
          return view.$router.push({ name: view.$route.name, query: {} })

        view.model[this.name].search = { ...view.model[this.name].bulkSearch }

        this.get(view)
      },

      changePerPage: value => {
        const userSettings = useUserSettingsStore()

        view.model[this.name].perPage = value

        userSettings.$patch(state => {
          state.filters[`per-page-${this.form}`] = value
          state.hasChanged = true
        })

        switch (this.name) {
          case 'tabs':
            view.modules[this.name].uses.get(view)
            break

          default:
            view.modules[this.name].uses.init(view)
            break
        }
      },

      table: res => {
        const listFiltersStore = useListFiltersStore(this.form)

        const { filters, columns, hiddenColumns } =
          storeToRefs(listFiltersStore)

        const { perPage, page } = view.model[this.name]
        const datasetLength = res.dataset.length

        let total = page * perPage
        if (datasetLength === view.model[this.name].perPage) {
          total = page * perPage + perPage
        }
        view.model[this.name].total = total

        const formFields = res.form.fields.map(f => ({
          ...f,
          name: f.field_name,
          field: f.field_name,
          label: f.field_label,
          type: f.field_type,
          id: f.id,
          condition: f.field_condition,
          render: view.model[this.name].activeTab
            ? view.model[this.name].activeTab.view.props.elements.find(
                e => e.field === f.field_name || e.element === f.field_type
              )
            : view.elements[this.name].find(
                e => e.field === f.field_name || e.element === f.field_type
              )
        }))

        columns.value = formFields

        if (res.user_advanced_filters && res.user_advanced_filters.length > 0) {
          filters.value = res.user_advanced_filters
        }

        view.params[this.name] = {
          ...view.params[this.name],
          title: res.form.form_name,
          data: res.dataset,
          columns: formFields,
          hiddenColumns
        }

        view.params[this.name].selected = res.dataset.find(
          row => row.id === this.getSelectedRow.id
        )

        if (view.model.bulk) view.params.bulk.active = true
      },
      setTransitionsId: param => {
        view.params[this.name].transitionsId = param.row.id
      },

      getActions: row => {
        const usesStateMachine = view.$checkFeature('uses-state-machine')

        if (row.isLoadingActions) return

        view.$set(row, 'isLoadingActions', true)

        if (row.dropActions && row.dropActions.length > 0) {
          row.isLoadingActions = false
          return
        }

        row.dropActions = []
        view.params[this.name].listActionsMsg = null

        view.api({
          module: null,
          method: 'post',
          endPoint:
            view.modules[this.name].props.endPoints.actions ||
            view.modules[this.name].props.endPoints.transitions,
          body: { id: row.id },
          noLoading: true,
          callback: res => {
            let listActionsMsg =
              !res.transitions.length && usesStateMachine
                ? view.$t('no-transitions-available')
                : null

            view.model[this.name].modal = {}

            const modelName = view.model[this.name].model ?? null
            const modelSlugFeature = setModelFeatureToSlug({ modelName })

            res.transitions = res.transitions.concat(
              actions({ view, row, usesStateMachine, modelSlugFeature })
            )

            listActionsMsg =
              !usesStateMachine && !res.transitions.length
                ? 'No actions available.'
                : listActionsMsg

            view.params[this.name].listActionsMsg = listActionsMsg

            row.dropActions.push(
              ...res.transitions.map(action => ({
                ...omit(row, 'dropActions'),
                label: action.name,
                class: action.class,
                onClick: () =>
                  view.handles[this.name].checkModal(
                    action,
                    omit(row, 'dropActions')
                  )
              }))
            )

            row.isLoadingActions = false
          }
        })
      },

      checkModal: (action, row) => {
        const hasMultiForms =
          action.params.forms && action.params.forms.length > 0

        const formType = !hasMultiForms
          ? action.params.form?.type
          : 'REGULAR_FORM'

        if (action.redirect_page) {
          row.transition = action.params.transition

          view.$router.push(
            action.redirect_page
              .split('/')
              .map(path => (path.includes(':') ? row[path.slice(1)] : path))
              .join('/')
          )

          return
        }

        switch (formType) {
          case 'INFLATE_LIST_FORM':
            view.modal({
              component: 'DynamicInflateForm',
              canCancel: ['x'],
              props: {
                res: null,
                model: view.model[this.name].modal,
                params: {
                  title: action.name
                },
                handles: {
                  api: view.api,
                  ...inflateForm.handles(view),
                  onCreate: _this => {
                    const idKeyName = `${_this.$convertToSnakeCase(
                      view.model[this.name].model
                    )}_id`

                    view.api({
                      module: this,
                      method: 'post',
                      endPoint: action.params.flow,
                      body: {
                        form: action.params.form.slug,
                        id: action.params[idKeyName]
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.form.name = res.form.form_name

                        _this.form.buttons = res.form.buttons

                        _this.formBuilder({
                          ...res,
                          formDependency: action.params.form.slug
                        })
                      }
                    })
                  },
                  onSubmit: (_this, button) => {
                    _this.flagDisable = true
                    _this.formValidation({
                      success: result => {
                        this.handles(view).setAction({
                          ...action,
                          upload: result.upload,
                          finally: () => {
                            _this.flagDisable = false
                            return _this.$emit('close')
                          },
                          onCancel: () => {
                            _this.flagDisable = false
                            return result.onCancel()
                          },
                          params: {
                            ...action.params,
                            ...result.payload
                          }
                        })
                      }
                    })
                  }
                }
              }
            })
            break
          case 'REGULAR_FORM':
          case 'EDIT_FORM':
            view.modal({
              component: 'DynamicForm',
              canCancel: ['x'],
              props: {
                res: null,
                model: view.model[this.name].modal,
                params: {
                  storeFields: [],
                  storeForms: [],
                  title: action.name,
                  forms:
                    action.params.forms && action.params.forms.length
                      ? action.params.forms
                      : [action.params.form.slug]
                },
                hasCard: 'true',
                handles: {
                  ...forms.handles(view),
                  api: view.api,
                  onCreate: _this => {
                    const idKeyName = `${_this.$convertToSnakeCase(
                      view.model[this.name].model
                    )}_id`

                    return view.api({
                      endPoint:
                        _this.actualForm.flow ||
                        action.params.flow ||
                        'authenticated-forms',
                      method: 'post',
                      body: {
                        form: _this.actualForm.slug || action.params.form.slug,
                        id: action.params[idKeyName]
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.form.notifications = res.notifications

                        _this.formBuilder({
                          formDependency: action.params.form.slug,
                          ...res,
                          fields: res.form
                        })
                      },
                      errorCallback: () => {
                        _this.$emit('close')
                      }
                    })
                  }
                },
                elements: [
                  {
                    if: _this =>
                      !_this.form.submitted &&
                      _this.isMultiStep &&
                      _this.form.step > 1,
                    component: 'b-button',
                    label: view.$t('button-back'),
                    class: 'materials-modal-footer-buttons',
                    loading: false,
                    disabled: ({ previous }) => previous,
                    onClick: (_this, next, previous) => {
                      return _this.handles.changeStep(
                        _this,
                        undefined,
                        previous
                      )
                    }
                  },
                  {
                    if: _this =>
                      !_this.form.submitted &&
                      _this.isMultiStep &&
                      !_this.isLastStep &&
                      _this.params.forms.length,
                    component: 'b-button',
                    label: view.$t('button-next'),
                    class: 'is-primary materials-modal-footer-buttons',
                    loading: false,
                    onClick: (_this, next) => {
                      return _this.handles.changeStep(_this, next, undefined)
                    }
                  },
                  {
                    if: _this =>
                      !_this.form.submitted &&
                      _this.form.step === _this.params.forms.length &&
                      (_this.params.forms.length > 1 ||
                        ['REGULAR_FORM', 'EDIT_FORM', 'MATRIX'].includes(
                          _this.form.type
                        ) ||
                        !_this.isMultiStep),
                    component: 'b-button',
                    label: view.$t('button-submit'),
                    class: 'is-primary materials-modal-footer-buttons',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        action,
                        success: result => {
                          this.handles(view).setAction({
                            ...action,
                            upload: result.upload,
                            finally: () => _this.$emit('close'),
                            onCancel: () => result.onCancel(),
                            params: {
                              ...action.params,
                              ...result.payload
                            }
                          })
                        }
                      })
                  },
                  {
                    component: 'b-button',
                    label: view.$t('button-close'),
                    onClick: _this => _this.$emit('close')
                  }
                ]
              }
            })
            break
          default:
            this.handles(view).setAction(action)
            break
        }
      },

      setAction: async action => {
        switch (true) {
          case action.download:
            downloadFileNew(action.endpoint, action.params).then(response => {
              if (response.status === 'error') {
                if (action.finally) action.finally()
                return view.$buefy.toast.open({
                  duration: 3000,
                  message: view.$t(response.message),
                  position: 'is-top',
                  type: 'is-danger'
                })
              }

              if (
                typeof response.file_name !== 'undefined' &&
                typeof response.file_content !== 'undefined'
              ) {
                const data = Uint8Array.from(atob(response.file_content), c =>
                  c.charCodeAt(0)
                )
                const blob = new Blob([data], {
                  type: 'octet/stream'
                })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.style = 'visibility:hidden'
                link.download = response.file_name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                return
              }

              const url = window.URL.createObjectURL(response)
              const today = moment(Date.now()).format('DD.MM.YYYY HH.mm.ss')
              const a = document.createElement('a')
              const extension =
                mime.extension(response.type) === 'html'
                  ? 'xml'
                  : mime.extension(response.type)

              a.href = url
              a.download = `${
                action.params.title ?? action.title
              } - ${today}.${extension}`

              a.click()
              window.URL.revokeObjectURL(url)

              if (action.finally) action.finally()
            })
            break

          case action.dialog !== false:
            const setToTranslate = view.$i18n.t('action-dialog-set-to', {
              actionName: action.name
            })
            const messageCode = action.model
              ? `sm-confirm-${action.model}-flow`
              : 'sm-confirm-flow'
            view.dialog({
              hasIcon: true,
              type: action.type,
              title: action.title || setToTranslate,
              message:
                action.message ||
                view.$i18n.t(messageCode, {
                  transition: action.name
                }),
              onCancel: () => {
                if (action.onCancel) action.onCancel()
              },
              onConfirm: () =>
                this.handles(view).setAction({ ...action, dialog: false })
            })
            break

          default:
            if (action.upload) {
              try {
                await this.handles(view).upload(action)
              } catch (e) {
                view.dialog({
                  method: 'alert',
                  type: 'is-danger',
                  icon: 'alert-circle',
                  ariaRole: 'alertdialog',
                  ariaModal: true,
                  title: view.$i18n.t('error-modal-title'),
                  message:
                    action.message || view.$i18n.t('order-item-upload_error')
                })
                return action.onCancel()
              }
            }

            view.api({
              module: this,
              method: 'post',
              endPoint: action.endpoint,
              body: { ...action.params },
              callback: async res => {
                const container = Object.keys(res.actions || {})[0] || undefined

                if (action.finally) action.finally(res)

                if (
                  this.handles(view).responseAction({ res, action })[container]
                )
                  this.handles(view)
                    .responseAction({ res, action })
                    [container]()
                else
                  this.handles(view).responseAction({ res, action }).default()
              }
            })
            break
        }
      },

      upload: async action => {
        let formId = null
        if (action.params.Document.form_model_id) {
          formId = action.params[`${action.params.Document.form_model_id}`].id
            ? action.params[`${action.params.Document.form_model_id}`].id
            : action.params[action.params.model][
                `${action.params.Document.form_model_id
                  .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
                  .substring(1)}_id`
              ]
        }

        return action.upload(
          action.params.Document.files,
          action.params.Document.form_model_id
            ? action.params.Document.form_model_id
            : action.params.model,
          action.params.Document.form_model_id
            ? formId
            : action.params[
                `${action.params.model
                  .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
                  .substring(1)}_id`
              ],
          action.params.Document.type_id,
          action.params.Document.document_type_id
        )
      },

      responseAction: ({ res, action }) => ({
        default: () => {
          view.model[this.name].modal = {}

          if (action.refresh !== false) {
            view.modules[this.name].uses.get(view)
          }

          let message = ''

          switch (true) {
            case typeof Object.values(res)[0] === 'string':
              message = view.$t(Object.values(res)[0])
              break
            case typeof res === 'object':
              message = view.$t(
                res.actions[Object.keys(res.actions)[0]].route_response.content
              )
              break
            default:
              break
          }

          view.toast({
            type: 'is-success',
            duration: 5000,
            message
          })
        },
        order_transition_history: () => {
          const data =
            res.actions.order_transition_history.route_response.content

          const columns = !data.form.fields
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        searchable: false,
                        class: 'list-actions',
                        content: features.filter(f =>
                          this.features.includes(f.feature)
                        )
                      }
                    : null
              }))

          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        },
        order_item_transition_history: () => {
          const data =
            res.actions.order_item_transition_history.route_response.content

          const columns = !data.form.fields
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        searchable: false,
                        class: 'list-actions',
                        content: features.filter(f =>
                          this.features.includes(f.feature)
                        )
                      }
                    : null
              }))

          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        },
        price_matrix_preview_feature: () => {
          const data =
            res.actions.price_matrix_preview_feature.route_response.content

          const columns = !data.form.fields
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        searchable: false,
                        class: 'list-actions',
                        content: features.filter(f =>
                          this.features.includes(f.feature)
                        )
                      }
                    : null
              }))
          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        },

        getorderinvoices: () => {
          const data = res.actions.getorderinvoices.route_response.content

          const columns = !data.form
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        content: [
                          {
                            if: () => true,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Download',
                              icon: 'download'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ _this, row }) =>
                              downloadFileNew(
                                `invoicing/document-builder/download/${row.document_builder_id}`
                              ).then(response => {
                                if (response.status === 'error')
                                  return _this.$buefy.toast.open({
                                    message: _this.$t(
                                      'invoicing-download_error'
                                    ),
                                    position: 'is-top',
                                    type: 'is-danger',
                                    duration: 3000
                                  })

                                if (
                                  typeof response.file_name !== 'undefined' &&
                                  typeof response.file_content !== 'undefined'
                                ) {
                                  const data = Uint8Array.from(
                                    atob(response.file_content),
                                    c => c.charCodeAt(0)
                                  )
                                  const blob = new Blob([data], {
                                    type: 'octet/stream'
                                  })
                                  const link = document.createElement('a')
                                  link.href = window.URL.createObjectURL(blob)
                                  link.style = 'visibility:hidden'
                                  link.download = `${row.document_builder_id}.pdf`
                                  document.body.appendChild(link)
                                  link.click()
                                  document.body.removeChild(link)
                                  return
                                }

                                const url = window.URL.createObjectURL(response)
                                const a = document.createElement('a')

                                a.href = url
                                a.download = `${row.document_builder_id}.pdf`

                                a.click()
                                window.URL.revokeObjectURL(url)
                              })
                          }
                        ]
                      }
                    : null
              }))

          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        }
      }),

      onFieldClick: (parent, row, field, index = null) => {
        if (row.documents) {
          Object.keys(row.documents).forEach(key => {
            row.documents[key].documented_added_at = parent.$dateTimeFormatter(
              row.documents[key].created_at
            )
            if (row.documents[key].document_type) {
              row.documents[key].document_type_description =
                row.documents[key].document_type.description
            }
          })
        }

        let hub = {
          docmodal: () => {
            view.modal({
              component: 'DynamicList',
              props: {
                params: {
                  paginated: false,
                  filterable: false,
                  title: 'Documents',
                  data: row.documents,
                  columns: [
                    {
                      name: 'name',
                      field: 'name',
                      label: view.$t('docmodal-document-name'),
                      type: 'text'
                    },
                    {
                      name: 'documented_added_at',
                      field: 'documented_added_at',
                      label: view.$t('docmodal-document-added'),
                      type: 'datetime'
                    },
                    {
                      name: 'document_type_description',
                      field: 'document_type_description',
                      label: view.$t('docmodal-document-type'),
                      type: 'text'
                    },
                    {
                      name: 'id',
                      field: 'id',
                      label: view.$t('docmodal-document-download'),
                      type: 'text',
                      render: {
                        field: 'id',
                        content: [
                          {
                            if: () => true,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Download',
                              icon: 'download'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ row }) => {
                              const data = { document_id: row.id }
                              getDocument(data).then(response => {
                                const aux =
                                  response.data.actions.get_document_binary
                                    .route_response.content
                                const mime =
                                  response.data.actions.get_document_binary
                                    .route_response.content.mimetype
                                downloadFile(aux.location).then(response => {
                                  const blobAux = new Blob([response.data], {
                                    type: mime
                                  })
                                  downloadFilePopup(
                                    window.URL.createObjectURL(blobAux),
                                    aux.real_name
                                  )
                                })
                              })
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: 'id',
                      field: 'id',
                      label: view.$t('docmodal-document-delete'),
                      type: 'text',
                      render: {
                        field: 'id',
                        content: [
                          {
                            if: ({ row }) => row.can_delete,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Delete',
                              icon: 'delete'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ _this, row }) => {
                              view.dialog({
                                type: 'is-danger',
                                hasIcon: true,
                                title: view.$t('remove-document-title'),
                                message: view.$t(
                                  'remove-document-confirm-message'
                                ),
                                onCancel: () => {},
                                onConfirm: () => {
                                  const data = {
                                    document_id: row.id
                                  }

                                  const apiResponse = api

                                    .post('delete-file-order', data)

                                    .then(response => {
                                      if (response.code === 200) {
                                        const index = parent.form.model[
                                          field.field_name
                                        ].findIndex(obj => {
                                          return obj.id === row.id
                                        })

                                        parent.form.model[
                                          field.field_name
                                        ].splice(
                                          index,

                                          1
                                        )
                                      }
                                    })

                                  _this.$emit('close')

                                  return apiResponse
                                }
                              })
                            }
                          }
                        ]
                      }
                    }
                  ]
                },
                model: {}
              }
            })
          }
        }

        hub = hub[field.type] || (() => null)

        hub()
      },

      openField: () => ({
        docmodal: (col, row) => view.modal()
      }),

      isRowCheckable: row => !window.transiting[row.id]
    }
  },

  elements(view) {
    return [
      {
        field: 'actions',
        searchable: false,
        class: 'list-actions',
        content: [
          {
            if: () => view.$checkFeature('uses-state-machine', 'v1'),
            sequency: 1000,
            component: 'DynamicListActions',
            content: { element: 'b-icon', title: 'View', class: 'is-small' },
            class: 'is-primary is-small rounded-icons',
            position: 'is-top-left',
            onClick: ({ row }) => view.handles[this.name].getActions(row)
          }
        ]
          .concat(elements({ view }))
          .concat(features.filter(f => this.features.includes(f.feature)))
          .concat(
            (view.modules[this.name].props.actions || []).map(action => ({
              if: () => true,
              ...action,
              onClick: ({ _this, row }) => {
                switch (true) {
                  case !!action.redirect:
                    const actionRedirect = action.redirect.replace(
                      /:(\w+)/g,
                      param => row[param.slice(1)]
                    )

                    view.$router.push(actionRedirect)
                    break
                  case !!action.dialog:
                    view.handles[this.name].setAction({
                      ...action,
                      message: view.$t(action.message),
                      title: view.$t(action.title),
                      endpoint: action.endpoint
                        .split('/')
                        .map(path =>
                          path.includes(':') ? row[path.slice(1)] : path
                        )
                        .join('/'),
                      params: mapValues(
                        mapKeys(action.params, (value, key) => value),
                        value => row[value]
                      )
                    })
                    break
                  default:
                    break
                }
              }
            }))
          )
          .sort((a, b) => a.sequency - b.sequency)
      },
      {
        field: 'state_name',
        content: [
          {
            if: ({ _this, row }) => row.state_name !== '',
            component: 'span',
            class: 'tag is-secondary'
          }
        ]
      },
      {
        field: 'created_at',
        content: [
          {
            if: ({ _this, row }) => row.created_at !== '',
            component: 'span',
            class: 'tag is-secondary'
          }
        ]
      },
      {
        element: 'boolean',
        content: [
          {
            if: () => true,
            render: ({ _this, row, field }) => {
              let active = 'active-cell'
              if (parseInt(row[field]) === 0) active = 'red-dot active-cell'
              if (parseInt(row[field]) === 1) active = 'green-dot active-cell'
              return {
                component: 'span',
                class: active
              }
            }
          }
        ]
      },
      {
        field: 'success_items',
        content: [
          {
            if: () => true,
            render: ({ _this, row }) => {
              return {
                'show-value': true,
                component: 'b-progress',
                type: 'is-info',
                content: `${row.success_items + row.failed_items} / ${
                  row.total_items
                }`,
                value:
                  ((row.success_items + row.failed_items) * 100) /
                  row.total_items
              }
            }
          }
        ]
      },
      {
        field: 'failed_items',
        content: [
          {
            if: () => true,
            render: ({ _this, row }) => {
              let component = 'a'
              let textDecoration = 'text-decoration-underline'
              if (row.failed_items === 0) {
                component = 'span'
                textDecoration = ''
              }
              return {
                'show-value': true,
                component,
                content: row.failed_items,
                class: textDecoration
              }
            },
            onClick: ({ _this, row }) => {
              if (row.results === null || row.results === undefined) {
                return
              }

              const itemsDetail = JSON.parse(row.results).filter(
                item => item.status === 'failure'
              )

              if (itemsDetail.length === 0) {
                return
              }

              view.modal({
                component: 'DynamicList',
                props: {
                  params: {
                    paginated: false,
                    filterable: true,
                    title: `Failure ${_this.params.title}`,
                    data: itemsDetail,
                    columns: [
                      {
                        name: 'name',
                        field: 'name',
                        label: view.$t('asynchronous-requests-name'),
                        type: 'text'
                      },
                      {
                        name: 'status',
                        field: 'status',
                        label: view.$t('asynchronous-requests-status'),
                        type: 'text'
                      },
                      {
                        name: 'date',
                        field: 'date',
                        label: view.$t('asynchronous-requests-date'),
                        type: 'date'
                      },
                      {
                        name: 'message',
                        field: 'message',
                        label: view.$t('asynchronous-requests-message'),
                        type: 'text'
                      }
                    ]
                  },
                  model: {}
                }
              })
            }
          }
        ]
      }
    ]
  }
}
