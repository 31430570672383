import axios from 'axios'
import { storeToRefs } from 'pinia'
import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy'
import { config } from '@/helpers/config'
import { api } from '@/services/api'
import { i18n } from '@/plugins/i18n'
import { useUserStore } from '../stores/user'
import { useAppStore } from '../stores/app'

export {
  getDocument,
  getGenericData,
  uploadDocument,
  downloadFile,
  downloadFileNew,
  downloadFilePopup,
  externalUploadDocument,
  getCompareDocument,
  deleteDocument
}

async function getDocument(form) {
  const apiResponse = await api.post('get-document-binary', form)
  return apiResponse
}

async function getGenericData(uri, data) {
  const apiResponse = await api.post(uri, data)
  return apiResponse
}

async function deleteDocument(document, row) {
  const data = {
    document_id: document.id
  }
  Dialog.confirm({
    title: i18n.t('remove-document-title'),
    message: `${i18n.t('remove-document-confirm-message')}`,
    type: 'is-danger',
    hasIcon: true,
    onCancel: () => {},
    onConfirm: () => {
      const apiResponse = api.post('delete-file-order', data).then(response => {
        if (response.code === 200) {
          const index = this.files.findIndex(obj => {
            return obj.id === document.id
          })
          this.files.splice(index, 1)
        }
      })
      this.isCardModalActive = false
      return apiResponse
    }
  })
}

async function downloadFileNew(endpoint, data = {}) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { token } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token.value}`
    },
    responseType: 'blob'
  }

  if (i18n.locale) {
    data.locale = i18n.locale
  }

  const apiResponse = await axios.post(
    `${config.ApiUrl}${endpoint}?client_key=${clientKey.value}`,
    data,
    axiosConfig
  )

  const blobAux = new Blob([apiResponse.data], {
    type: apiResponse.headers['content-type']
  })

  try {
    const blobResponse = JSON.parse(await blobAux.text())
    return blobResponse
  } catch (error) {}

  return blobAux
}

async function downloadFile(location) {
  const axiosConfig = {
    responseType: 'blob'
  }

  const apiResponse = await axios.post(
    `${config.ApiUrl}download-file-order`,
    { document_location: location },
    axiosConfig
  )

  return apiResponse
}

async function getCompareDocument(location) {
  const params = { document_location: location, base64: true }
  const APIResponse = await api.post('download-file-order', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

function downloadFilePopup(fileUrl, fileName) {
  const a = document.createElement('a')
  a.href = fileUrl
  a.setAttribute('download', fileName)
  a.click()
}

async function uploadDocument(files, model, itemId, typeId, documentTypeId) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { token } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  let endpoint = `${config.ApiUrl}upload-documents?client_key=${clientKey.value}&model=${model}&model_item_id=${itemId}`
  if (typeId) {
    endpoint += `&type_id=${typeId}`
  }
  if (documentTypeId) {
    endpoint += `&document_type_id=${documentTypeId}`
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token.value}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  const toReturn = await axios
    .post(endpoint, files.append, axiosConfig)
    .then(response => {
      if (
        response.data.actions.documents_upload.route_response.http_code === 400
      ) {
        throw response.data.actions.documents_upload.route_response.content
      }
      return response
    })
    .catch(error => {
      throw error
    })

  return toReturn
}

async function externalUploadDocument(
  files,
  model,
  itemId,
  typeId,
  documentTypeId
) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { token } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  let endpoint = `${config.ApiUrl}external-upload-documents?client_key=${clientKey.value}&model=${model}&model_item_id=${itemId}`
  if (typeId) {
    endpoint += `&type_id=${typeId}`
  }
  if (documentTypeId) {
    endpoint += `&document_type_id=${documentTypeId}`
  }
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token.value}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  const toReturn = await axios
    .post(endpoint, files.append, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })

  return toReturn
}
