import { defineStore } from 'pinia'

export const usePageStore = defineStore('pages', {
  state: () => {
    return {
      pages: {},
      formData: {},
      page: {
        label: '',
        hideHerobar: true,
        classes: {
          container: '',
          sectionContainer: ''
        },
        to: '',
        title: '',
        menu: {},
        modules: [],
        tabs: [],
        buttons: [],
        features: [],
        notifications: []
      },
      appNotifications: [],
      stack: [],
      selectedRow: {
        routeName: null,
        selected: null
      },
      currentFormId: null,
      priceMatrixSlug: null,
      viewManager: {},
      order: {}
    }
  },
  actions: {
    resetPages() {
      this.pages = {}
      this.appNotifications = []
      this.stack = []
      this.selectedRow = {}
      this.currentFormId = null
      this.priceMatrixSlug = null
      this.viewManager = {}
      this.order = {}
      this.formData = {}
    }
  },
  persist: true
})
