import list from './list'

export default {
  init(view) {
    const tabs = view.modules[this.name].uses.tabs.map(tab =>
      tab.view.type !== 'list'
        ? {
            ...tab,
            view: {
              ...tab.view,
              component: 'DynamicForm',
              props: {
                res: null,
                model: {},
                params: {
                  title: tab.name,
                  forms: [tab.view.form]
                },
                handles: {
                  api: view.api,
                  onCreate: _this =>
                    view.api({
                      endPoint: tab.view.flow || 'authenticated-forms',
                      method: 'post',
                      body: {
                        form: tab.view.form,
                        id: view.$route.params.id
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.form.type = res.form.form_type
                        view.model[this.name].model = res.form.form_model

                        _this.formBuilder({
                          formDependency: tab.view.form,
                          ...res,
                          fields: res.form.fields
                        })
                      }
                    })
                },
                elements: [
                  {
                    if: _this => _this.form.type === 'EDIT_FORM',
                    component: 'b-button',
                    label: view.$t('button-submit'),
                    class: 'is-primary',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        model: view.model[this.name].modal,
                        success: result => {
                          view.api({
                            method: 'post',
                            module: this,
                            endPoint: tab.view.submit_flow,
                            body: {
                              ...result.payload,
                              form_slug: tab.view.form
                            },
                            callback: async res => {
                              res = res.actions
                                ? res.actions[Object.keys(res.actions)[0]]
                                    .route_response.content
                                : res

                              if (result.upload) {
                                await result.upload(
                                  result.payload.Document.files,
                                  result.payload.Document.form_model_id
                                    ? result.payload.Document.form_model_id
                                    : view.model[this.name].model,
                                  res.id,
                                  result.payload.Document.type_id,
                                  result.payload.Document.document_type_id
                                )
                              }

                              view.params[this.name].res = res

                              _this.form.submitted = true
                              if (
                                tab.view.props.endPoints &&
                                tab.view.props.endPoints.list
                              ) {
                                view.$router.push(tab.view.props.endPoints.list)
                              } else {
                                _this.handles.onCreate(_this)
                              }
                            }
                          })
                        }
                      })
                  }
                ]
              }
            }
          }
        : {
            ...tab,
            view: {
              ...tab.view,
              component: 'DynamicList',
              props: {
                res: {},
                model: {
                  search: {}
                },
                handles: {
                  ...list.handles(view),

                  onCreate: _this => {
                    list.name = 'tabs'
                    list.init(view)

                    view.api({
                      module: this,
                      endPoint: tab.view.flow,
                      method: 'post',
                      body: {
                        form: tab.view.form,
                        id: view.$route.params.id,
                        page_tab_id: view.model[this.name].activeTab.id
                      },
                      callback: res => {
                        res =
                          res.dataFake ||
                          res.actions[Object.keys(res.actions)[0]]
                            .route_response.content

                        view.model[this.name].model = res.form.form_model

                        list.handles(view).table(res)
                      }
                    })
                  }
                },
                elements: [
                  {
                    field: 'actions',
                    searchable: false,
                    class: 'list-actions',
                    content: tab.view.actions
                      .map(action => ({
                        if: () => true,
                        sequency: tab.id,
                        component: 'b-button',
                        content: {
                          element: 'b-icon',
                          title: view.$i18n.t(action.label),
                          icon: action.icon
                        },
                        class: 'is-primary is-small rounded-icons',
                        onClick: ({ _this, row }) =>
                          view.modal({
                            component: 'DynamicForm',
                            props: {
                              res: null,
                              model: {},
                              params: {
                                title: action.label,
                                forms: [action.form]
                              },
                              hasCard: 'true',
                              handles: {
                                api: view.api,
                                onCreate: _this =>
                                  view.api({
                                    endPoint:
                                      action.flow || 'authenticated-forms',
                                    method: 'post',
                                    body: {
                                      form: action.form,
                                      id: row.id
                                    },
                                    callback: res => {
                                      res = res.actions
                                        ? res.actions[
                                            Object.keys(res.actions)[0]
                                          ].route_response.content
                                        : res

                                      _this.formBuilder({
                                        formDependency: action.form,
                                        ...res,
                                        fields: res.form
                                      })
                                    }
                                  })
                              },
                              elements: [
                                {
                                  component: 'b-button',
                                  label: 'button-close',
                                  onClick: _this => _this.$emit('close')
                                },
                                {
                                  component: 'b-button',
                                  label: view.$t('button-submit'),
                                  class: 'is-primary',
                                  loading: false,
                                  onClick: _this => {
                                    _this.formValidation({
                                      action,
                                      model: _this.model,
                                      success: result => {
                                        view.api({
                                          endPoint: action.submit_flow,
                                          method: 'post',
                                          body: {
                                            ...result.payload,
                                            form_slug: action.form
                                          },
                                          callback: async res => {
                                            res = res.actions
                                              ? res.actions[
                                                  Object.keys(res.actions)[0]
                                                ].route_response.content
                                              : res

                                            _this.$emit('close')

                                            if (result.upload) {
                                              const formModel = view.model[
                                                this.name
                                              ].activeTab.model
                                                ? view.model[this.name]
                                                    .activeTab.model
                                                : view.model[this.name].model

                                              await result.upload(
                                                result.payload.Document.files,
                                                result.payload.Document
                                                  .form_model_id
                                                  ? result.payload.Document
                                                      .form_model_id
                                                  : formModel,
                                                res.id,
                                                result.payload.Document.type_id,
                                                result.payload.Document
                                                  .document_type_id
                                              )
                                            }

                                            view.params[this.name].tabs
                                              .find(
                                                tab =>
                                                  tab.id ===
                                                  Number(
                                                    view.model[this.name]
                                                      .activeTab.id
                                                  )
                                              )
                                              .view.props.handles.onCreate()

                                            view.toast({
                                              type: 'is-success',
                                              message: res.message
                                            })
                                          }
                                        })
                                      }
                                    })
                                  }
                                }
                              ]
                            }
                          })
                      }))
                      .concat(
                        (tab.view.props.buttons || []).map(action => ({
                          if: ({ row }) =>
                            action.feature
                              ? view.$checkFeature(action.feature, 'v1') &&
                                !row.deleted_at
                              : true,
                          sequency: tab.id,
                          component: 'b-button',
                          content: {
                            element: 'b-icon',
                            title: view.$i18n.t(action.title),
                            icon: action.icon
                          },
                          class: `is-primary is-small rounded-icons ${action.errorClass}`,
                          onClick: ({ _this, row }) =>
                            _this.handles.setAction({
                              dialog: true,
                              type: action.type,
                              title: view.$i18n.t(action.messageTitle),
                              message: view.$i18n.t(action.message),
                              endpoint: action.endpoint,
                              params: { id: row.id }
                            })
                        }))
                      )
                  },
                  {
                    element: 'boolean',
                    content: [
                      {
                        if: () => true,
                        render: ({ _this, row, field }) => {
                          let active = 'active-cell'
                          if (parseInt(row[field]) === 0)
                            active = 'red-dot active-cell'
                          if (parseInt(row[field]) === 1)
                            active = 'green-dot active-cell'
                          return {
                            component: 'span',
                            class: active
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
    )

    view.api({
      endPoint: 'handle-tab-conditions',
      method: 'post',
      body: {
        id: view.$route.params.id,
        form: tabs[0].view.form
      },
      callback: res => {
        res = res.actions
          ? res.actions[Object.keys(res.actions)[0]].route_response.content
          : res

        tabs.forEach((tab, index) => {
          if (tab.conditions.length > 0) {
            tab.conditions.forEach((tabCondition, c) => {
              const model = tabCondition.form_field.form_model.name
              const fieldName = tabCondition.form_field.extended_field
                ? tabCondition.form_field.extended_field_conn.name
                : tabCondition.form_field.field_model_name
              const fieldValue = tabCondition.field_value

              // if it is a relation
              if (res.model_info[model] && res.model_info[model].length > 0) {
                tabs[index].visible = false
                res.model_info[model].forEach((entry, i) => {
                  if (String(entry[fieldName]) === fieldValue) {
                    tabs[index].visible = true
                    return true
                  }
                })
              } else if (!res.model_info[model]) {
                tabs[index].visible = false
              } else if (
                res.model_info[model] &&
                String(res.model_info[model][fieldName]) !== fieldValue
              ) {
                tabs[index].visible = false
              }
            })
          }

          if (tabs[index].visible === false) {
            return
          }

          if (tab.company_types.length === 0) {
            return
          }
          const companyTypeHasTab = tab.company_types.some(type =>
            res.company_types.includes(type)
          )

          tabs[index].visible = companyTypeHasTab
        })
      }
    })

    view.$set(view.params[this.name], 'tabs', tabs)
    view.$set(view.model[this.name], 'activeTab', tabs[0])
    view.$set(view.model[this.name], 'activeTab', tabs[0])
    view.$set(view.model[this.name], 'activeTabIndex', 0)
    view.$set(view.model[this.name], 'dowpdown', false)

    if (
      view.page.max_tabs_for_display !== '' &&
      tabs.length > view.page.max_tabs_for_display
    ) {
      view.$set(view.model[this.name], 'dowpdown', true)
    }
  },

  get(view) {
    view.api({
      module: this,
      endPoint: view.model.tabs.activeTab.view.flow || 'authenticated-forms',
      method: 'post',
      body: {
        form: view.model.tabs.activeTab.view.form,
        id: view.$route.params.id,
        page_tab_id: view.model[this.name].activeTab.id
      },
      callback: res => {
        res = res.actions
          ? res.actions[Object.keys(res.actions)[0]].route_response.content
          : res

        view.model[this.name].model = res.form.form_model

        list.handles(view).table(res)
      }
    })
  },

  handles(view) {
    return {
      onChange: tabId => {
        view.model[this.name].activeTab =
          view.params[this.name].tabs[Number(tabId)]

        view.model[this.name].activeTabIndex = tabId
      },

      buttonClick: button =>
        view.modal({
          component: 'DynamicForm',
          props: {
            res: null,
            model: {},
            params: {
              title: button.label,
              forms: [button.form]
            },
            hasCard: 'true',
            handles: {
              api: view.api,
              onCreate: _this =>
                view.api({
                  endPoint: button.flow || 'authenticated-forms',
                  method: 'post',
                  body: {
                    form: button.form
                  },
                  callback: res => {
                    res = res.actions
                      ? res.actions[Object.keys(res.actions)[0]].route_response
                          .content
                      : res

                    view.model[this.name].activeTab.model = res.form.form_model

                    _this.formBuilder({
                      formDependency: button.form,
                      ...res,
                      fields: res.form
                    })
                  }
                })
            },
            elements: [
              {
                component: 'b-button',
                label: 'button-close',
                onClick: _this => _this.$emit('close')
              },
              {
                component: 'b-button',
                label: view.$t('button-submit'),
                class: 'is-primary',
                loading: false,
                onClick: _this => {
                  _this.formValidation({
                    button,
                    model: _this.model,
                    success: result => {
                      view.api({
                        endPoint: button.submit_flow,
                        method: 'post',
                        body: {
                          ...result.payload,
                          page_tab_id: view.model[this.name].activeTab.id,
                          form_slug: button.form
                        },
                        callback: async res => {
                          res = res.actions
                            ? res.actions[Object.keys(res.actions)[0]]
                                .route_response.content
                            : res

                          _this.$emit('close')

                          if (result.upload) {
                            await result.upload(
                              result.payload.Document.files,
                              view.model[this.name].activeTab.model
                                ? view.model[this.name].activeTab.model
                                : view.model[this.name].model,
                              res.id,
                              result.payload.Document.type_id,
                              result.payload.Document.document_type_id
                            )
                          }

                          view.params[this.name].tabs
                            .find(
                              tab =>
                                tab.id ===
                                Number(view.model[this.name].activeTab.id)
                            )
                            .view.props.handles.onCreate()

                          view.toast({
                            type: 'is-success',
                            message: res.message
                          })

                          location.reload()
                        }
                      })
                    }
                  })
                }
              }
            ]
          }
        })
    }
  }
}
