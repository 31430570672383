<template>
  <section>
    <b-collapse
      class="card"
      animation="slide"
      aria-id="contentIdForA11y3"
      :open="!schema.isClosed"
    >
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="contentIdForA11y3"
      >
        <p class="card-header-title">
          {{ schema.name }}
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
        </a>
      </div>
      <div class="card-content">
        <slot></slot>
      </div>
    </b-collapse>
  </section>
</template>
<script>
export default {
  props: ['model', 'schema']
}
</script>
<style></style>
