import { storeToRefs } from 'pinia'
import setFormModel from '@/services/form-builder/setFormModel'
import getComponentByType from '@/services/form-builder/getComponentByType'
import replaceHtmlVars from '@/services/form-builder/replaceHtmlVars'
import getValue from '@/services/form-builder/getValue'
import setValue from '@/services/form-builder/setValue'
import parseValueByType from '@/services/form-builder/parseValueByType'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useUtilitiesStore } from '@/stores/utilities'

/**
 * @param {*} props
 * @helper : Set the field properties
 */

export default function setProperties(props) {
  const fieldProp = {}

  /* Field Properties */
  fieldProp.type = getComponentByType(props.field_type)
  fieldProp.id = props.id
  fieldProp.model = props.field_name
  fieldProp.name = props.field_name
  fieldProp.object_property = props.object_property
  fieldProp.hidden = props.field_type === 'hidden'
  fieldProp.flabel = props.field_label
  fieldProp.mandatory = props.field_mandatory
  fieldProp.placeholder = props.field_placeholder
  fieldProp.inputType = props.field_properties.name
  fieldProp.default = props.field_default_value
  fieldProp.disabled = props.disabled > 0
  fieldProp.show_on_preview = props.show_on_preview === 1
  fieldProp.dataType = props.hasOwnProperty('datatype') ? props.datatype : null
  fieldProp.html = props.field_html
  fieldProp.showLabel = props.show_label
  fieldProp.vars = props.vars
  fieldProp.position = props.position
  fieldProp.showPreviousValue = props.show_previous_value
  fieldProp.hierarchySlug = props.hierarchy_slug

  if (
    fieldProp.type === 'openhtml' &&
    fieldProp.vars &&
    fieldProp.vars.length !== 0
  ) {
    fieldProp.html = replaceHtmlVars(fieldProp.html, fieldProp.vars)
  }

  /* Set the value on the model */
  if (props.field_name !== null && props.field_name !== undefined) {
    setFormModel(props)
  }

  const fieldTypesNotExpanded = ['checkbox', 'readonly', 'open_html']

  /* Check if the field has additonal properties */
  if (props.hasOwnProperty('properties')) {
    fieldProp.properties = props.properties
  }

  /* Check if the field has parent and add extra css */
  if (
    props.hasOwnProperty('field_has_parent') &&
    !fieldTypesNotExpanded.includes(props.field_type)
  ) {
    fieldProp.styleClasses = ['field', 'is-expanded']
  }

  /* Search for options in field properties */
  if (props.hasOwnProperty('options')) {
    /* Additional information about the lookups */
    fieldProp.lookup_value = props.lookup_value
    fieldProp.lookup_text = props.lookup_text

    const selectData = {}
    fieldProp.values = []
    for (const option in props.options) {
      /* We need to migrate to this new structure soon, it's more dynamic */
      if (
        (window.formType === 'EDIT_FORM' ||
          window.formType === 'REGULAR_FORM' ||
          window.formType === 'OVERVIEW_FORM') &&
        props.field_type === 'tags'
      ) {
        selectData[option] = {
          [props.lookup_value]: props.options[option][props.lookup_value],
          [props.lookup_text]: props.options[option][props.lookup_text]
        }
      } else {
        selectData[option] = {
          value: props.options[option][props.lookup_value],
          text: props.options[option][props.lookup_text]
        }
      }

      fieldProp.values.push(selectData[option])
    }
  }

  /* Search for conditions in field properties */
  if (props.hasOwnProperty('condition')) {
    for (const condition in props.condition) {
      switch (props.condition[condition].type) {
        case 'VISIBLE':
          fieldProp.visible = model => {
            /*
                Se tiver mais que 2 condições fisible
                Loop pela condição
              */
            if (Object.keys(model).length > 0) {
              let result = false
              let value = model[props.condition[condition].dependency]
              const r = []

              if (props.condition[condition].dependency.indexOf('.') > 0) {
                value = getValue(
                  model,
                  String(props.condition[condition].dependency)
                )
              }

              // check if value is an object
              const isObjectOrArray =
                typeof value === 'object' && value !== null

              if (isObjectOrArray) {
                switch (props.condition[condition].operation) {
                  case '===':
                    result =
                      value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionEqual = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) === conditionEqual
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '!==':
                    result =
                      value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionDifferent = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) !== conditionDifferent
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '>':
                    result =
                      value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionMore = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) > conditionMore
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '<':
                    result =
                      value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionValue = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) < conditionValue
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case 'in':
                    const conditionArray =
                      props.condition[condition].rule.split(',')

                    Object.entries(value).forEach(([key, v]) => {
                      result = conditionArray.includes(String(value[key].id))
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                }

                return result
              }
              switch (props.condition[condition].operation) {
                case '===':
                  return (
                    model &&
                    value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case '!==':
                  return (
                    model &&
                    value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case '>':
                  return (
                    model &&
                    value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case '<':
                  return (
                    model &&
                    value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case 'in':
                  return props.condition[condition].rule
                    .split(',')
                    .includes(String(value))
              }
            }
          }
          break
        case 'VISIBLE_AND_CLEAR':
          fieldProp.visible = (model, field) => {
            if (Object.keys(model).length > 0) {
              let result = false
              let value = model[props.condition[condition].dependency]
              const r = []

              if (props.condition[condition].dependency.indexOf('.') > 0) {
                value = getValue(
                  model,
                  String(props.condition[condition].dependency)
                )
              }

              // check if value is an object
              const isObjectOrArray =
                typeof value === 'object' && value !== null

              if (isObjectOrArray) {
                switch (props.condition[condition].operation) {
                  case '===':
                    result =
                      value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionEqual = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) === conditionEqual
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '!==':
                    result =
                      value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionDifferent = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) !== conditionDifferent
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '>':
                    result =
                      value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionMore = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) > conditionMore
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '<':
                    result =
                      value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionValue = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) < conditionValue
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case 'in':
                    const conditionArray =
                      props.condition[condition].rule.split(',')

                    Object.entries(value).forEach(([key, v]) => {
                      result = conditionArray.includes(String(value[key].id))
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case 'all':
                    result = false
                    if (value != null) {
                      result = true
                    }
                    break
                }
              } else {
                switch (props.condition[condition].operation) {
                  case '===':
                    result =
                      value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case '!==':
                    result =
                      value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case '>':
                    result =
                      value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case '<':
                    result =
                      value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case 'in':
                    result = props.condition[condition].rule
                      .split(',')
                      .includes(String(value))
                    break
                  case 'all':
                    result = false
                    if (value != null) {
                      result = true
                    }
                    break
                }
              }

              /* Set value null when run the disable and clear */
              if (result === false) {
                if (
                  getValue(model, String(field.name)) !== null &&
                  getValue(model, String(field.name)) !== undefined
                ) {
                  setValue(model, field.name.split('.'), null)
                }
              }

              return result
            }
          }
          break
        case 'DISABLED':
          fieldProp.disabled = model => {
            if (Object.keys(model).length > 0) {
              let result = false
              let value = model[props.condition[condition].dependency]
              const r = []

              if (props.condition[condition].dependency.indexOf('.') > 0) {
                value = getValue(
                  model,
                  String(props.condition[condition].dependency)
                )
              }

              // check if value is an object
              const isObjectOrArray =
                typeof value === 'object' && value !== null

              if (isObjectOrArray) {
                switch (props.condition[condition].operation) {
                  case '===':
                    result =
                      value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionEqual = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) === conditionEqual
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '!==':
                    result =
                      value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionDifferent = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) !== conditionDifferent
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '>':
                    result =
                      value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionMore = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) > conditionMore
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '<':
                    result =
                      value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionValue = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) < conditionValue
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case 'in':
                    const conditionArray =
                      props.condition[condition].rule.split(',')

                    Object.entries(value).forEach(([key, v]) => {
                      result = conditionArray.includes(String(value[key].id))
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                }

                return result
              }
              switch (props.condition[condition].operation) {
                case '===':
                  return (
                    model &&
                    value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case '!==':
                  return (
                    model &&
                    value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case '>':
                  return (
                    model &&
                    value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case '<':
                  return (
                    model &&
                    value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                  )
                case 'in':
                  return props.condition[condition].rule
                    .split(',')
                    .includes(String(value))
              }
            }
          }
          break
        case 'DISABLE_AND_CLEAR':
          fieldProp.disabled = (model, field) => {
            if (Object.keys(model).length > 0) {
              let result = false
              let value = model[props.condition[condition].dependency]
              const r = []

              if (props.condition[condition].dependency.indexOf('.') > 0) {
                value = getValue(
                  model,
                  String(props.condition[condition].dependency)
                )
              }

              // check if value is an object
              const isObjectOrArray =
                typeof value === 'object' && value !== null

              if (isObjectOrArray) {
                switch (props.condition[condition].operation) {
                  case '===':
                    result =
                      value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionEqual = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) === conditionEqual
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '!==':
                    result =
                      value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionDifferent = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) !== conditionDifferent
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '>':
                    result =
                      value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionMore = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) > conditionMore
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case '<':
                    result =
                      value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    const conditionValue = parseValueByType(
                      props.condition[condition].dependency_data_type,
                      props.condition[condition].rule
                    )

                    Object.entries(value).forEach(([key, v]) => {
                      result = String(value[key].id) < conditionValue
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                  case 'in':
                    const conditionArray =
                      props.condition[condition].rule.split(',')

                    Object.entries(value).forEach(([key, v]) => {
                      result = conditionArray.includes(String(value[key].id))
                      r.push(result)
                    })
                    result = r.includes(true)
                    break
                }
              } else {
                switch (props.condition[condition].operation) {
                  case '===':
                    result =
                      value ===
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case '!==':
                    result =
                      value !==
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case '>':
                    result =
                      value <
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case '<':
                    result =
                      value >
                      parseValueByType(
                        props.condition[condition].dependency_data_type,
                        props.condition[condition].rule
                      )
                    break
                  case 'in':
                    result = props.condition[condition].rule
                      .split(',')
                      .includes(String(value))
                    break
                }
              }

              /* Set value null when run the disable and clear */
              if (result === true) {
                if (
                  getValue(model, String(field.name)) !== null &&
                  getValue(model, String(field.name)) !== undefined
                ) {
                  setValue(model, field.name.split('.'), null)
                }
              }

              return result
            }
          }
          break
        case 'FILTER':
          fieldProp.run_dependency = true
          fieldProp.conditions = []
          props.condition.forEach(condition => {
            if (condition.company_dependency) {
              fieldProp.company_dependency = condition.company_dependency
            }
            fieldProp.conditions.push(condition.dependency)
          })
          // fieldProp.onChanged = function (model, newVal, oldVal, field, edit = false) {
          //   /* Since it's the edit, we need to get the current value from the model */
          //   // if (edit === true) {
          //   //   newVal = getValue(model, String(newVal))
          //   // }

          //   if (typeof newVal === 'undefined') {
          //     return
          //   }

          //   let formSlug = model.form_slug
          //   if (Object.keys(completeFormModel).length > 0) {
          //     formSlug = (completeFormModel.form_slug) ? completeFormModel.form_slug : model.form_slug
          //   }

          //   /* Format the request for the dependency */
          //   let dependencyProps = {
          //     form: formSlug,
          //     field_id: props.id,
          //     field_value: newVal,
          //     locale: i18n.locale
          //   }

          //   /* Request to get and fill the data for the dependencies */
          //   runDependency(dependencyProps).then(response => {
          //     for (let data in response) {
          //       if (Array.isArray(response[data])) {
          //         traceFields[data].values = response[data]
          //         /* Clean the value from the field after run dependency */
          //         if (edit === false && getValue(model, String(traceFields[data].model)) !== null && getValue(model, String(traceFields[data].model)) !== undefined) {
          //           setValue(model, traceFields[data].model.split('.'), null)
          //         }
          //       } else {
          //         setValue(model, data.split('.'), response[data])
          //       }
          //     }
          //   })
          // }
          break
        case 'CLEAR':
          fieldProp.clear = model => {
            setValue(
              model,
              props.condition[condition].dependency.split('.'),
              null
            )
          }
          break
        case 'TESTE':
          fieldProp.visible = model => {
            if (Object.keys(model).length > 0) {
              const auxResult = []
              const ruleThreshold = currentValue => currentValue === true

              const configs = {
                visible: [
                  [
                    {
                      model: 'OrderItem.detail_type',
                      operator: '===',
                      value: 1
                    },
                    {
                      model: 'OrderItem.quantity',
                      operator: '>',
                      value: 5
                    }
                  ],
                  [
                    {
                      model: 'OrderItem.detail_type',
                      operator: '===',
                      value: 2
                    }
                  ]
                ]
              }

              for (const config in configs.visible) {
                auxResult[config] = []
                for (const rule in configs.visible[config]) {
                  let result = false
                  let value = model[configs.visible[config][rule].model]

                  if (configs.visible[config][rule].model.indexOf('.') > 0) {
                    value = getValue(
                      model,
                      String(configs.visible[config][rule].model)
                    )
                  }

                  switch (configs.visible[config][rule].operator) {
                    case '===':
                      result =
                        value ===
                        parseValueByType(
                          props.condition[condition].dependency_data_type,
                          configs.visible[config][rule].value
                        )
                      break
                    case '!==':
                      result =
                        value !==
                        parseValueByType(
                          props.condition[condition].dependency_data_type,
                          configs.visible[config][rule].value
                        )
                      break
                    case '>':
                      result =
                        value >
                        parseValueByType(
                          props.condition[condition].dependency_data_type,
                          configs.visible[config][rule].value
                        )
                      break
                    case '<':
                      result =
                        value <
                        parseValueByType(
                          props.condition[condition].dependency_data_type,
                          configs.visible[config][rule].value
                        )
                      break
                  }
                  auxResult[config].push(result)
                }
              }

              for (const resultRule in auxResult) {
                if (auxResult[resultRule].every(ruleThreshold)) {
                  return true
                }
              }

              return false
            }
          }
          break
      }
    }
  }

  /* Search for validations in field properties */
  if (props.hasOwnProperty('validation')) {
    const fieldAux = props.validation
    props.validation.forEach(validationType => {
      switch (validationType.type) {
        case 'REGEX':
          fieldProp.validator = (value, field, model) => {
            const reg = new RegExp(fieldAux.rule)
            if (!reg.test(value)) {
              return [validationType.error_message]
            }
            return []
          }
          break
        case 'COMMON':
          fieldProp.validatorCommon = validationType.rule.join('|')
          fieldProp.RegexValidatorErrorMessage = validationType.error_slug
          validationType.rule.forEach(validation => {
            if (validation.includes('max:')) {
              fieldProp.flength = validation.split(':')[1]
            }
          })
          break
      }
    })
  }

  /* Trace the field to manipulate */
  const utilitiesStore = useUtilitiesStore()
  const { currentForm } = storeToRefs(utilitiesStore)
  if (!window.traceFields[currentForm.value]) {
    window.traceFields[currentForm.value] = []
  }
  window.traceFields[currentForm.value][props.field_name] = fieldProp

  return fieldProp
}
