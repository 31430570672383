import axios from 'axios'
import { storeToRefs } from 'pinia'
import { i18n } from '@/plugins/i18n'
import { api } from '@/services/api'
import router, { resetRouter } from '@/router'
import { config } from '@/helpers/config'
import { useAppStore } from '@/stores/app'
import { useUtilitiesStore } from '@/stores/utilities'
import { useUserStore } from '@/stores/user'
import { useUserSettingsStore } from '@/stores/userSettings'
import { usePageStore } from '@/stores/pages'
import { useMenuStore } from '@/stores/menus'
import { uploadDocument } from '@/services/document'
import { addDynamicRoutes } from '@/services/helpers/pages'

export {
  login,
  logout,
  register,
  profile,
  updateDetails,
  roles,
  validateEmail,
  update,
  users,
  uploadAvatar,
  recovery,
  requestRecovery
}

async function login(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('login', params.form).then(response => {
    if ('accessToken' in response.data) {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      )
      return response.data
    }
  })

  return APIResponse
}

async function logout() {
  const appStore = useAppStore()
  const { clientKey } = storeToRefs(appStore)
  const userStore = useUserStore()
  const userSettings = useUserSettingsStore()
  const pageStore = usePageStore()
  const menusStore = useMenuStore()
  const { id } = storeToRefs(userStore)

  userStore.$patch({
    previousUserId: id.value
  })

  await api.post('logout')

  const key = clientKey.value
  const language = i18n.locale

  userStore.$reset()
  pageStore.$reset()
  menusStore.$reset()

  userSettings.$patch(state => {
    state.filters = {}
  })

  resetRouter.then(() => {
    router.replace({ name: 'login', params: { key, language } }).catch(() => {})
  })
}

async function register(form) {
  const APIResponse = await api.post('register', form).then(response => {
    form.User.id = response.data.user.id

    fileUpload(form, 'User')
    if (response.code === 200) {
      if ('accessToken' in response.data) {
        return true
      }
      return response
    }
  })

  return APIResponse
}

async function profile(locale = 'en') {
  const appStore = useAppStore()
  const params = {}

  params.locale = locale
  const APIResponse = await api.post('details', params)

  if (APIResponse.code === 200) {
    if (APIResponse.data.success && APIResponse.data.success.features) {
      appStore.$patch({
        features: APIResponse.data.success.features
      })
    }

    if (APIResponse.data.success && APIResponse.data.success.dateConfig) {
      appStore.$patch({
        dateConfig: APIResponse.data.success.dateConfig
      })
    }

    if (APIResponse.data.success && APIResponse.data.success.number_format) {
      appStore.$patch({
        numberFormat: APIResponse.data.success.number_format
      })
    }

    return APIResponse
  }
}

async function updateDetails(detailsVersion) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const menuStore = useMenuStore()
  const pageStore = usePageStore()
  const utilitiesStore = useUtilitiesStore()
  const userSettingsStore = useUserSettingsStore()

  const { language } = storeToRefs(userStore)

  const profileRes = await profile(i18n.locale)

  const details = profileRes.data.success

  userStore.$patch({
    id: details.id,
    name: details.name,
    email: details.email,
    role: details.role,
    abilities: details.abilities,
    roleCode: details.role_code,
    avatar: details.avatar,
    language: details.languageCode ?? 'en'
  })

  menuStore.$patch({
    menus: details.menus
  })

  appStore.$patch({
    numberFormat: details.number_format,
    chunkModelUpload: details.chunk_model_uploads
  })

  pageStore.resetPages()
  pageStore.$patch({
    pages: details.pages,
    page: {},
    appNotifications: details.app_notifications,
    selectedRow: {
      routeName: null,
      selected: null
    },
    currentFormId: null,
    priceMatrixSlug: null,
    viewManager: {},
    order: {}
  })

  utilitiesStore.$patch({
    countries: {},
    measures: {},
    containers: {},
    loadings: {},
    currentForm: null,
    currentFormId: null,
    perPageConfig: null
  })

  userSettingsStore.$patch({
    active: true
  })

  resetRouter.then(() => {
    addDynamicRoutes()

    if (language == null || language === {}) {
      userStore.$patch({
        language: 'en'
      })
    }

    i18n.locale = language
  })

  if (!detailsVersion) return

  userStore.$patch({
    detailsVersion
  })
}

async function users(role = '', company = '', locale = 'en') {
  let params = {}
  params.locale = locale
  params.form = 'list-form-users'

  if (role !== '') {
    params = { role }
  } else if (company !== '') {
    params = { company }
  }

  const APIResponse = await api.post('users', params)

  if (APIResponse.code === 200) {
    return APIResponse.data
  }
}

async function roles() {
  const APIResponse = await api.post('app-roles')

  if (APIResponse.code === 200) {
    return APIResponse.data
  }
}

async function update(form) {
  fileUpload(form, 'User')
  const endpoint = 'user-update'

  const APIResponse = await api.post(endpoint, form)

  if (APIResponse.code === 200) {
    return APIResponse.data
  }
}

async function uploadAvatar(newAvatar) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { token, avatar } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const data = new FormData()
  data.append('avatar', newAvatar)

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token.value}`,
      'Content-Type': 'image/png'
    }
  }

  const APIResponse = await axios.post(
    `${config.ApiUrl}update?client_key=${clientKey.value}`,
    data,
    axiosConfig
  )

  avatar.value = APIResponse.data.success.avatar

  return APIResponse.data
}

async function validateEmail(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('verify-email', params.form)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function requestRecovery(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('request-recovery', params.form)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function recovery(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('recovery', params.form)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function fileUpload(form, model) {
  if (form.files) {
    form.model = model
    await uploadDocument(
      form.files,
      form.model,
      form[form.model].id,
      form.Document.type_id
    ).then(response => {
      return response
    })
  }
}
