/* Styles */
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import 'tippy.js/dist/tippy.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import { storeToRefs } from 'pinia'

/* Form Builder */
import VueFormGenerator from 'vue-form-generator'

/* Service Worker */
import './registerServiceWorker'

import VueSimpleUploader from 'vue-simple-uploader'
import ModuleLibrary from 'vfg-field-array'
import VfgFieldObject from 'vfg-field-object'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueMeta from 'vue-meta'
import Treeselect from '@riophae/vue-treeselect'
import Echo from 'laravel-echo'
import PortalVue from 'portal-vue'
import tinymce from 'vue-tinymce-editor'

import { useLayoutStore } from '@/stores/layout'
import { useUserStore } from '@/stores/user'
import pinia from './pinia'

import AsideMenuList from '@/components/AsideMenuList'
import fieldAbstract from '@/components/fields/fieldAbstract'
import fieldAwesome from '@/components/fields/fieldAwesome'
import fieldSection from '@/components/fields/fieldSection'
import fieldGrouped from '@/components/fields/fieldGrouped'
import fieldCheck from '@/components/fields/fieldCheckbox'
import fieldSubmit from '@/components/fields/fieldButton'
import fieldReadonly from '@/components/fields/fieldReadonly'
import fieldTitle from '@/components/fields/fieldTitle'
import fieldImagenize from '@/components/fields/fieldImagenize'
import fieldTime from '@/components/fields/fieldTime'
import fieldTextarea from '@/components/fields/fieldTextarea'
import fieldPassword from '@/components/fields/fieldPassword'
import fieldView from '@/components/fields/fieldView'
import fieldCard from '@/components/fields/fieldCard'
import fieldDate from '@/components/fields/fieldDate'
import fieldSpace from '@/components/fields/fieldSpace'
import fieldSeparator from '@/components/fields/fieldSeparator'
import fieldFiles from '@/components/fields/fieldFiles'
import fieldBoolean from '@/components/fields/fieldBoolean'
import fieldOpenhtml from '@/components/fields/fieldOpenhtml'
import fieldTags from '@/components/fields/fieldTags'
import fieldHeader from '@/components/fields/fieldHeader'
import fieldDocmodal from '@/components/fields/fieldDocmodal'
import Container from '@/components/Container'
import Accordion from '@/components/Accordion'
import Materials from '@/components/Materials'
import ListActions from '@/components/ListActions'
import FormGeneratorModal from '@/components/FormGeneratorModal'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import './vee-validate'
import Utilities from '@/plugins/utilities.js'
import '@/plugins/mixins'
import { config } from '@/helpers/config'
import App from './App.vue'
import router from './router'
import { loadMessages } from './plugins/i18n'

const i18n = require('./plugins/i18n').i18n

const ListForm = () => import('@/components/ListForm')

window.Event = new Vue()

router.afterEach(() => {
  const layoutStore = useLayoutStore()
  const htmlClassName = 'has-aside-mobile-expanded'

  if (layoutStore.isAsideMobileExpanded) {
    document.documentElement.classList.add(htmlClassName)
  } else {
    document.documentElement.classList.remove(htmlClassName)
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(Utilities)
Vue.use(ModuleLibrary)
Vue.use(VfgFieldObject)
Vue.use(VueFormGenerator)
Vue.use(require('vue-moment'))

Vue.use(PortalVue)

Vue.use(VueMeta)
Vue.use(VueSimpleUploader)

Vue.use(VueReCaptcha, { siteKey: '6LcuWP0UAAAAACiXwkBIILHwOL6LFbIAZ7ERgYD7' })
Vue.component('AsideMenuList', AsideMenuList)
Vue.component('fieldAbstract', fieldAbstract)
Vue.component('ListForm', ListForm)
Vue.component('fieldAwesome', fieldAwesome)
Vue.component('fieldSection', fieldSection)
Vue.component('fieldGrouped', fieldGrouped)
Vue.component('fieldSubmit', fieldSubmit)
Vue.component('fieldCheck', fieldCheck)
Vue.component('fieldTitle', fieldTitle)
Vue.component('fieldTime', fieldTime)
Vue.component('fieldDate', fieldDate)
Vue.component('fieldReadonly', fieldReadonly)
Vue.component('fieldTextarea', fieldTextarea)
Vue.component('fieldPassword', fieldPassword)
Vue.component('fieldView', fieldView)
Vue.component('fieldSpace', fieldSpace)
Vue.component('fieldImagenize', fieldImagenize)
Vue.component('fieldCard', fieldCard)
Vue.component('fieldSeparator', fieldSeparator)
Vue.component('fieldFiles', fieldFiles)
Vue.component('fieldBoolean', fieldBoolean)
Vue.component('fieldOpenhtml', fieldOpenhtml)
Vue.component('fieldTags', fieldTags)
Vue.component('fieldHeader', fieldHeader)
Vue.component('fieldDocmodal', fieldDocmodal)
Vue.component('Container', Container)
Vue.component('Accordion', Accordion)
Vue.component('Materials', Materials)
Vue.component('ListActions', ListActions)
Vue.component('FormGeneratorModal', FormGeneratorModal)
Vue.component('Treeselect', Treeselect)
Vue.component('ListForm', ListForm)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('tinymce', tinymce)

window.Pusher = require('pusher-js')

new Vue({
  pinia,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

const userStore = useUserStore(pinia)
const { token } = storeToRefs(userStore)

if (process.env.NODE_ENV === 'production' && token.value) {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: config.websocketsAppKey,
    wsHost: config.websocketsServer,
    authEndpoint: `${config.baseUrl}broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${token.value}`
      }
    },
    wsPort: 6001,
    encrypted: true,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss']
  })
}

loadMessages()
