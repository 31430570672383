import list from './list.js'
import listFilters from './listFilters.js'
import listBulk from './listBulk.js'
import forms from './forms.js'
import inflateForm from './inflateForm.js'
import inflateTableForm from './inflateTableForm.js'
import tabs from './tabs.js'
import buttons from './buttons.js'
import openException from './openException'
import stepsView from './stepsView.js'

import actions from './actions'
import elements from './elements'

export default {
  list,
  listFilters,
  listBulk,
  forms,
  inflateForm,
  inflateTableForm,
  tabs,
  actions,
  elements,
  buttons,
  openException,
  stepsView
}
