export default {
  init(view) {
    view.params[this.name] = view.$route.params
  },

  handles(view) {
    return {
      onCreate: _this => {
        view.api({
          module: this,
          method: 'post',
          endPoint: view.modules[this.name].uses.flow || '/authenticated-forms',
          body: {
            ...view.params[this.name],
            form: view.modules[this.name].uses.form
          },
          callback: res => {
            res = res.actions
              ? res.actions[Object.keys(res.actions)[0]].route_response.content
              : res

            view.params[this.name].reference_hash = res.reference_hash

            _this.form.name = res.form.form_name

            _this.form.buttons = res.form.buttons

            _this.formBuilder({
              ...res,
              formDependency: view.modules[this.name].uses.form
            })
          }
        })
      },

      onSubmit: (_this, button) => {
        _this.flagDisable = true
        view.params[this.name].transition = button.value

        _this.formValidation({
          success: result => {
            view.api({
              method: 'post',
              module: this,
              endPoint: view.modules[this.name].uses.submitFlow,
              body: {
                ...result.payload,
                ...view.params[this.name],
                form: view.modules[this.name].uses.form
              },
              callback: async res => {
                res = res.actions
                  ? res.actions[Object.keys(res.actions)[0]].route_response
                      .content
                  : res

                view.toast({
                  type: 'is-success',
                  message: res.message
                })
                _this.flagDisable = false
                view.$router.push('/declarations')
              }
            })
          }
        })
      },

      getAllLevels: levels => {
        const reduceLevels = arr =>
          arr.reduce((list, { children, ...level }) => {
            list.push(level)
            if (children) list.push(...reduceLevels(children))
            return list
          }, [])

        return reduceLevels(levels)
      },

      getInputFields: fields => {
        const inputFields = fields.reduce((acc, field) => {
          const childs = Object.values(field.childs.fields).filter(
            field => field.field_type !== 'hidden'
          )

          return acc.concat(childs.length > 0 ? childs : [])
        }, [])

        return inputFields
      },

      levelHasChildren: level => level.children.length > 0,

      isLastLevel: (index, levelsLength) => index === levelsLength - 1,

      calculateTotals: (levels, mask, fieldIndex, getValue) => {
        const totals = []

        let unitOfMeasure = ''

        levels.forEach(level => {
          const field = this.handles(view).getInputFields(level.fields)[
            fieldIndex
          ]

          unitOfMeasure = field.properties.unit_of_measure

          const formattedValue = this.handles(view).formatValueForSum(
            field,
            getValue(field) || '0'
          )

          totals.push(formattedValue)
        })

        let total = totals.reduce((partialSum, a) => partialSum + a, 0)

        total = this.handles(view).formatTotal(!isNaN(total) ? total : 0, mask)

        const totalWithUnitOfMeasure = `${total} ${unitOfMeasure}`

        return `${totalWithUnitOfMeasure}`
      },

      formatValueForSum: (field, value) => {
        if (!field.mask) return !isNaN(value) ? parseFloat(value) : value

        let formattedValue = null

        if (!field.mask.numeralDecimalScale) {
          formattedValue = parseInt(value.replaceAll(field.mask.delimiter, ''))
        } else {
          formattedValue = parseFloat(
            parseFloat(
              value
                .replaceAll(field.mask.delimiter, '')
                .replace(field.mask.numeralDecimalMark, '.')
            ).toFixed(field.mask.numeralDecimalScale)
          )
        }

        return formattedValue
      },

      formatTotal: (value, mask) => {
        if (!mask) return value

        const localeString = mask.numeralDecimalMark === '.' ? 'en-US' : 'de-DE'

        const formattedValue = mask.numeralDecimalScale
          ? Intl.NumberFormat(localeString, {
              minimumFractionDigits: mask.numeralDecimalScale
            }).format(value)
          : Intl.NumberFormat(localeString).format(value)

        return formattedValue
      }
    }
  },

  elements(view) {
    return []
  }
}
