import { storeToRefs } from 'pinia'
import { api } from '@/services/api'
import { i18n } from '@/plugins/i18n'
import __init from '@/services/form-builder/init'
import handleViewForm from '@/services/form-builder/handleViewForm'
import handleLandingPage from '@/services/form-builder/handleLandingPage'
import handleListForm from '@/services/form-builder/handleListForm'
import handleGeneralForm from '@/services/form-builder/handleGeneralForm'
import { usePageStore } from '../../stores/pages'
import { useUtilitiesStore } from '@/stores/utilities'

/**
 * @param {*} endpoint
 * @param {*} form
 * @param {*} key
 * @param {*} options
 */

export default async function getForm(
  endpoint,
  form,
  options = '',
  locale = i18n.locale,
  listParams = {},
  otherParms = {}
) {
  let tmpResponse = null
  let params = { form, locale }

  const pageStore = usePageStore()
  const utilitiesStore = useUtilitiesStore()
  const { viewManager } = storeToRefs(pageStore)
  const { currentForm } = storeToRefs(utilitiesStore)

  currentForm.value = form

  if (options !== '') {
    params.id = options
  }

  if (Object.keys(listParams).length > 0) {
    params = { ...params, ...listParams }
    params.list_form = true
  }

  if (Object.keys(otherParms).length > 0) {
    params = { ...params, ...otherParms }
  }

  if (
    params.list_form &&
    viewManager.value &&
    viewManager.value[currentForm.value] &&
    viewManager.value[currentForm].value.selected
  ) {
    const selected = viewManager.value[currentForm.value].selected
    params.adv_search =
      viewManager.value[currentForm.value].saved[selected].conditions
  }

  const apiResponse = await api
    .post(endpoint, params)
    .then(data => {
      if (data.data.hasOwnProperty('actions')) {
        // eslint-disable-next-line no-unreachable-loop
        for (const key in data.data.actions) {
          tmpResponse = data.data.actions[key].route_response.content
          break
        }
      } else {
        tmpResponse = data.data
      }

      if (data.code === 200) {
        if (tmpResponse.hasOwnProperty('warning')) {
          alert(tmpResponse.warning)
        } else {
          __init()
          let fields = {}

          if (typeof tmpResponse !== 'string') {
            if (tmpResponse.hasOwnProperty('form')) {
              if (tmpResponse.form.hasOwnProperty('fields')) {
                window.formType = tmpResponse.form.form_type
                fields = tmpResponse.form.fields
              }
            } else {
              window.formType = tmpResponse.form_type
              fields = tmpResponse.fields
            }
          } else {
            alert(i18n.t('error-get-data'))
          }

          if (tmpResponse.hasOwnProperty('dataset')) {
            window.formDataset = tmpResponse.dataset
          }

          let totalResults = 0
          if (tmpResponse.hasOwnProperty('total_results')) {
            totalResults = tmpResponse.total_results
          }

          const formName = tmpResponse.form_name ?? tmpResponse.form.form_name
          const buttons = tmpResponse.buttons ?? tmpResponse.form.buttons
          let form = {}

          switch (window.formType) {
            case 'OVERVIEW_FORM':
              form = handleViewForm(fields, buttons)
              break
            case 'LANDING_PAGE':
              form = handleLandingPage(fields)
              break
            case 'LIST_FORM':
              form = handleListForm(
                fields,
                window.formDataset,
                totalResults,
                formName
              )
              break
            default:
              form = handleGeneralForm(fields, tmpResponse)
              break
          }

          // console.log(form)

          const pageStore = usePageStore()
          const { currentFormId, formData } = storeToRefs(pageStore)

          formData.value = tmpResponse.form
          currentFormId.value =
            tmpResponse.form_definition_id ??
            tmpResponse.form.form_id ??
            tmpResponse.form.form_definition_id

          form.buttons = buttons
          form.formName = formName

          if (tmpResponse.form.upload_template) {
            form.uploadTemplate = tmpResponse.form.upload_template
          }

          return form
        }
      }
    })
    .catch(error => {
      throw error
    })

  return apiResponse
}
