import { storeToRefs } from 'pinia'
import omit from 'lodash/omit'
import {
  getDocument,
  downloadFile,
  downloadFilePopup
} from '@/services/document'
import { usePageStore } from '@/stores/pages'
import { features } from '@/data/features'
import { api } from '@/services/api'
import inflateForm from './inflateForm'
import inflateTableForm from './inflateTableForm'
import stepsView from './stepsView'
import { useUserStore } from '@/stores/user'
import { logout } from '@/services/'
import actions from './actions'
import setModelFeatureToSlug from './slugModelFeatures'

export default {
  init(view) {
    const pageStore = usePageStore()
    let { page } = storeToRefs(pageStore)
    page = page.value ? page.value : {}

    view.$set(view.params[this.name], 'forms', view.modules[this.name].forms)
    view.$set(view.params[this.name], 'component', 'section')

    view.params[this.name].storeFields = []
    view.params[this.name].storeForms = []

    view.params[this.name].redirectAfterSubmit = ''

    view.params[this.name].isMultiStep = view.params[this.name].forms.length > 1

    view.model[this.name] = { ...view.model[this.name], ...view.$route.params }
  },

  handles(view) {
    return {
      api: view.api, // remove

      onCreate: _this =>
        view.api({
          module: this,
          method: 'post',
          endPoint: view.modules[this.name].uses.flow || '/authenticated-forms',
          body: {
            ...view.model[this.name],
            form: view.modules[this.name].uses.getFormSlug(_this.form.step - 1)
          },
          callback: res => {
            res = res.actions
              ? res.actions[Object.keys(res.actions)[0]].route_response.content
              : res

            _this.form.dataset = res.dataset

            if (res.dataset && res.dataset.hash)
              view.params[this.name].reference_hash = _this.form.dataset.hash

            _this.form.slug = res.form.form_slug
            _this.form.type = res.form.form_type

            if (view.$checkFeature('uses-form-buttons')) {
              _this.form.buttons = res.form.buttons
            }

            _this.form.notifications = res.notifications

            view.model[this.name].model = res.form.form_model

            // _this.form.submitted = false

            _this.formBuilder({
              ...res,
              formDependency: view.modules[this.name].uses.getFormSlug(
                _this.form.step - 1
              )
            })
          }
        }),

      changeStep: (_this, next, previous) => {
        if (previous) {
          _this.isLoading = true

          _this.fields = _this.params.storeFields.pop()
          _this.form = _this.params.storeForms.pop()

          previous()

          _this.isLoading = false

          return
        }

        if (_this.isSubmitStep || _this.isSubmitAllSteps) {
          _this.formValidation({
            success: () => {
              if (_this.isSubmitAllSteps) {
                _this.params.storeForms.push({ ..._this.form })
                _this.params.storeFields.push(_this.fields)
              }
              _this.handles.onSubmit(_this, next)
            }
          })

          return
        }

        _this.formValidation({
          success: () => {
            _this.params.storeForms.push({ ..._this.form })
            _this.params.storeFields.push(_this.fields)

            _this.form.step++

            next()

            _this.handles.onCreate(_this)
          }
        })
      },

      onSubmit: (_this, nextStep) => {
        _this.formValidation({
          fields: _this.handles.getFieldsToSubmitMultiStep(_this),
          success: result => {
            view.api({
              method: 'post',
              module: this,
              endPoint:
                view.modules[this.name]?.uses.submitFlow ||
                _this.actualForm.submit_flow,
              body: {
                ...view.$route.params,
                ...omit(
                  view.params[this.name],
                  'storeFields',
                  'storeForms',
                  'isMultiStep',
                  'res',
                  'component',
                  'forms',
                  'buttons',
                  'aditionalParams'
                ),
                ...result.payload,
                ...view.params[this.name].aditionalParams,
                form_slug:
                  _this.handles.getFormSlugToSubmit() || _this.actualForm.slug
              },
              callback: async res => {
                res = res.actions
                  ? res.actions[Object.keys(res.actions)[0]].route_response
                      .content
                  : res

                if (result.upload) {
                  await result.upload(
                    result.payload.Document.files,
                    result.payload.Document.form_model_id
                      ? result.payload.Document.form_model_id
                      : view.model[this.name].model,
                    res.id,
                    result.payload.Document.type_id,
                    result.payload.Document.document_type_id
                  )
                }

                if (this.name && view.params[this.name]) {
                  view.params[this.name].res = res
                }

                _this.form.submitted = !_this.isSubmitAllSteps

                if (res.message && !res.code) {
                  view.toast({
                    type: 'is-success',
                    message: res.message
                  })
                }

                switch (true) {
                  case nextStep !== undefined:
                    _this.form.step++

                    nextStep()

                    _this.handles.onCreate(_this)
                    break
                  case view.modules[this.name].props.submitComponent !==
                    undefined:
                    view.params[this.name].component =
                      view.modules[this.name].props.submitComponent

                    _this.isLoading = false
                    break
                  case view.params[this.name].redirectAfterSubmit !== '':
                    view.$router.push(
                      view.params[this.name].redirectAfterSubmit
                        .split('/')
                        .map(path =>
                          path.includes(':')
                            ? view.$route.params[path.slice(1)]
                            : path
                        )
                        .join('/')
                    )
                    break
                  case view.modules[this.name].props.redirect !== undefined:
                    const redirectTo = view.modules[this.name].props.redirect

                    if (redirectTo === '$reload') {
                      this.init(view)
                      this.handles(view).onCreate(_this)

                      return
                    }

                    view.$router.push(redirectTo)
                    break
                  case _this.form.editSubmit === true:
                    const modelName = _this.$convertToSnakeCase(
                      view.model[this.name].model
                    )

                    const insertedId =
                      typeof res === 'object'
                        ? res.id || res[modelName].id
                        : res

                    const redirect = view.modules[
                      this.name
                    ].props.editSubmitRedirect.includes(':id')
                      ? view.modules[
                          this.name
                        ].props.editSubmitRedirect.replace(':id', insertedId)
                      : view.modules[this.name].props.editSubmitRedirect
                    view.$router.push(redirect)
                    break
                  case view.$route.name === 'register':
                    view.toast({
                      type: 'is-success',
                      message: view.$i18n.t(view.params[this.name].res.success),
                      duration: 3000
                    })
                    view.$router.back()
                    break
                  case view.$route.name === 'passwordreset':
                    const userStore = useUserStore()
                    userStore.logout()
                    const logoutUser = logout
                    view.$router.push('/login')
                    break
                  case view.params[this.name].isModal !== true:
                    view.$router.back()
                    break
                  default:
                    break
                }
              }
            })
          }
        })
      },

      getFormSlugToSubmit: () => {
        return view.params[this.name]?.forms.find(({ type }) =>
          ['REGULAR_FORM', 'EDIT_FORM', 'MATRIX'].includes(type)
        )?.slug
      },

      getFieldsToSubmitMultiStep: _this => {
        // if the form is multistep get fields from storeFields
        if (_this.params.isMultiStep && !_this.isSubmitAllSteps) {
          const formsToSubmit = view.params[this.name].forms
            .filter(({ type }) =>
              ['REGULAR_FORM', 'EDIT_FORM', 'MATRIX'].includes(type)
            )
            .map(({ slug }) => slug)

          const fieldsToSubmit = view.params[this.name].storeFields
            .flat()
            .filter(field => formsToSubmit.includes(field.formSlug))

          return fieldsToSubmit
        }

        return undefined
      },

      getSubForm: (_this, field) => {
        const components = {
          INFLATE_LIST_FORM: () =>
            import('@/components/Dynamic/DynamicInflateForm'),
          INFLATE_TABLE_FORM: () =>
            import('@/components/Dynamic/DynamicTableInflate'),
          STEPS: () => import('@/components/Dynamic/DynamicStepsView')
        }

        const handles = {
          INFLATE_LIST_FORM: () => inflateForm.handles(view),
          INFLATE_TABLE_FORM: () => inflateTableForm.handles(view),
          STEPS: () => stepsView.handles(view)
        }

        const subFormIsSteps = field.field_type === 'steps'

        if (subFormIsSteps) {
          _this.$watch(
            () => _this.$route.query,
            // eslint-disable-next-line func-names
            function (_new, _old) {
              if (_new.step) return

              _this.isLoading = true

              _this.handles.onCreate(_this)
            }
          )
          field.sub_form.form = { form_type: field.field_type.toUpperCase() }
        }

        const subFormType = field.sub_form.form.form_type

        const formDependency = _this.form.slug

        const subFormComponentProps = {
          component: components[subFormType],
          res: null,
          model: {},
          params: {
            reference_hash: field.sub_form.reference_hash
          },
          handles: {
            ...handles[subFormType](),
            onCreate: _this => {
              if (subFormIsSteps && view.$route.query.step) {
                const activeStepForm = field.sub_form.data.find(
                  step => step.code === view.$route.query.step
                )

                _this.activeStepForm = {
                  component: 'DynamicForm',
                  props: stepsView
                    .handles(view)
                    .getActiveStepProps(activeStepForm)
                }
              }

              _this.formBuilder({
                ...field.sub_form,
                formDependency
              })
            },
            onSubmit: () => {}
          },
          elements: [],
          query: {}
        }

        return subFormComponentProps
      },

      deleteWaste: (parent, field, index, confirmCallback) =>
        view.dialog({
          type: 'is-danger',
          hasIcon: true,
          title: view.$t('order-item-remove'),
          message: view.$t('order-item-check-delete-item'),
          onCancel: () => {},
          onConfirm: () => {
            if (parent.form.model[field.field_name] && index != null) {
              parent.form.model[field.field_name].splice(index, 1)
              parent.form.lookups[field.field_name].splice(index, 1)
              parent.form.updates++

              if (parent.form.model[field.field_name].length === 0) {
                parent.form.model[field.field_name] = null
              }
            }

            view.params[this.name].isModal = false

            if (confirmCallback) confirmCallback()
          }
        }),
      onFieldClickModal: (parent, field, index = null) => {
        const subForm = field.sub_form.form
        const companyId = view.model.form.company_id
        const periodId = view.model.form.period_id

        view.modal({
          component: 'DynamicForm',
          canCancel: ['x'],
          props: {
            res: null,
            // model: subForm.form_model,
            params: {
              title: subForm.form_name,
              forms: [subForm.form_slug]
            },
            hasCard: 'true',
            handles: {
              api: view.api,
              onCreate: _this =>
                view.api({
                  endPoint: 'authenticated-forms',
                  method: 'post',
                  body: {
                    form: subForm.form_slug
                  },
                  callback: res => {
                    res = res.actions
                      ? res.actions[Object.keys(res.actions)[0]].route_response
                          .content
                      : res

                    _this.formBuilder({
                      formDependency: subForm.form_slug,
                      ...res,
                      fields: res.form
                    })
                  }
                })
            },
            elements: [
              {
                component: 'b-button',
                label: view.$t('button-close'),
                onClick: _this => _this.$emit('close')
              },
              {
                component: 'b-button',
                label: view.$t('button-submit'),
                class: 'is-primary',
                onClick: _this =>
                  _this.formValidation({
                    field,
                    success: result =>
                      view.dialog({
                        type: 'is-danger',
                        hasIcon: true,
                        title: view.$t('send-material-request-title'),
                        message: view.$t(
                          'send-material-request-confirm-message'
                        ),
                        onCancel: () => {},
                        onConfirm: () => {
                          view.api({
                            module: this,
                            method: 'post',
                            endPoint: field.properties.submit_flow,
                            body: {
                              ...field.params,
                              ...result.payload,
                              company_id: companyId,
                              period_id: periodId
                            },
                            callback: res => {
                              _this.$emit('close')
                              if (res.error) {
                                view.toast({
                                  type: 'is-danger',
                                  message: view.$t(res.error.message)
                                })
                                return
                              }
                              view.toast({
                                type: 'is-success',
                                message: view.$t(res.message)
                              })
                            }
                          })
                        }
                      })
                  })
              }
            ]
          }
        })
      },
      onFieldClick: (parent, field, index = null) => {
        let hub = {
          waste: () => {
            const model =
              parent.form.model[field.field_name] &&
              parent.form.model[field.field_name][index]
                ? parent.form.model[field.field_name][index]
                : {}

            const forms = [
              view.modules[this.name].uses.getFormSlug(parent.form.step - 1)
            ]

            view.params[this.name].model = parent.form.model

            view.modal({
              component: 'DynamicForm',
              parent,
              canCancel: false,
              props: {
                model,
                params: { ...view.params[this.name], forms },
                hasCard: 'true',
                handles: {
                  ...this.handles(view),
                  onCreate: _this => {
                    _this.formBuilder({
                      dataset:
                        parent.form.dataset &&
                        parent.form.dataset[field.raw_field_name][index],
                      formDependency: forms[0],
                      fields: field.childs.fields,
                      form: { form_type: 'REGULAR_FORM' }
                    })
                  }
                },
                elements: [
                  {
                    component: 'b-button',
                    label: view.$t('button-update'),
                    class: 'is-primary',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        modal: true,
                        success: () => {
                          if (
                            !Array.isArray(parent.form.model[field.field_name])
                          )
                            parent.form.model[field.field_name] = []

                          if (
                            !Array.isArray(
                              parent.form.lookups[field.field_name]
                            )
                          )
                            parent.form.lookups[field.field_name] = []

                          if (index === null) {
                            parent.form.model[field.field_name].push(
                              _this.form.model
                            )

                            parent.form.lookups[field.field_name].push(
                              _this.form.lookups
                            )
                          } else {
                            parent.form.model[field.field_name][index] =
                              _this.form.model

                            parent.form.lookups[field.field_name][index] =
                              _this.form.lookups
                          }

                          _this.$emit('close')

                          view.params[this.name].isModal = false

                          parent.form.updates++
                        }
                      })
                  },
                  {
                    component: 'b-button',
                    label: view.$t('button-remove'),
                    class: 'is-danger',
                    onClick: _this => {
                      field.deleteWaste(index, () => {
                        _this.$emit('close')
                      })
                    }
                  }
                ]
              }
            })
          },
          docmodal: () => {
            view.modal({
              component: 'DynamicList',
              props: {
                params: {
                  paginated: false,
                  filterable: false,
                  title: 'Documents',
                  data: parent.form.model[field.field_name],
                  columns: [
                    {
                      name: 'name',
                      field: 'name',
                      label: view.$t('docmodal-document-name'),
                      type: 'text'
                    },
                    {
                      name: 'created_at',
                      field: 'created_at',
                      label: view.$t('docmodal-document-created-at'),
                      type: 'datetime'
                    },
                    {
                      name: 'id',
                      field: 'id',
                      label: view.$t('docmodal-document-download'),
                      type: 'text',
                      render: {
                        field: 'id',
                        content: [
                          {
                            if: () => true,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Download',
                              icon: 'download'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ row }) => {
                              const data = { document_id: row.id }
                              getDocument(data).then(response => {
                                const aux =
                                  response.data.actions.get_document_binary
                                    .route_response.content
                                const mime =
                                  response.data.actions.get_document_binary
                                    .route_response.content.mimetype
                                downloadFile(aux.location).then(response => {
                                  const blobAux = new Blob([response.data], {
                                    type: mime
                                  })
                                  downloadFilePopup(
                                    window.URL.createObjectURL(blobAux),
                                    aux.real_name
                                  )
                                })
                              })
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: 'id',
                      render: {
                        field: 'id',
                        content: [
                          {
                            if: ({ row }) => row.can_delete,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Delete',
                              icon: 'delete'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ _this, row }) => {
                              view.dialog({
                                type: 'is-danger',
                                hasIcon: true,
                                title: view.$t('remove-document-title'),
                                message: view.$t(
                                  'remove-document-confirm-message'
                                ),
                                onCancel: () => {},
                                onConfirm: () => {
                                  const data = {
                                    document_id: row.id
                                  }

                                  const apiResponse = api

                                    .post('delete-file-order', data)

                                    .then(response => {
                                      if (response.code === 200) {
                                        const index = parent.form.model[
                                          field.field_name
                                        ].findIndex(obj => {
                                          return obj.id === row.id
                                        })

                                        parent.form.model[
                                          field.field_name
                                        ].splice(
                                          index,

                                          1
                                        )
                                      }
                                    })

                                  _this.$emit('close')

                                  return apiResponse
                                }
                              })
                            }
                          }
                        ]
                      }
                    }
                  ]
                },
                model: {}
              }
            })
          }
        }

        hub = hub[field.field_type] || (() => null)

        hub()
      },
      getActions: row => {
        const usesStateMachine = view.$checkFeature('uses-state-machine', 'v1')

        view.params[this.name].listActions = []
        view.params[this.name].listActionsMsg = null
        view.$set(view.params[this.name], 'isLoadingActions', true)

        view.api({
          module: null,
          method: 'post',
          endPoint: view.modules[this.name].props.endPoints.actions,
          body: { id: view.model[this.name].id },
          callback: res => {
            view.params[this.name].listActions = []
            view.model[this.name].modal = {}

            const row = view.model[this.name]
            const modelName = view.model[this.name].model ?? null
            const modelSlugFeature = setModelFeatureToSlug({ modelName })

            res.transitions = res.transitions.concat(
              actions({ view, row, usesStateMachine, modelSlugFeature })
            )

            let listActionsMsg =
              !res.transitions.length && usesStateMachine
                ? view.$t('no-transitions-available')
                : null

            listActionsMsg =
              !usesStateMachine && !res.transitions.length
                ? view.$t('no-transitions-available')
                : listActionsMsg

            view.params[this.name].listActionsMsg = listActionsMsg

            view.params[this.name].listActions.push(
              ...res.transitions.map(action => ({
                ...row,
                label: action.name,
                class: action.class,
                onClick: () => view.handles[this.name].checkModal(action)
              }))
            )

            view.params[this.name].isLoadingActions = false
          }
        })
      },
      checkModal: action => {
        const formType = action.params.form.type ?? action.params.form
        switch (formType) {
          case 'INFLATE_LIST_FORM':
            view.modal({
              component: 'DynamicInflateForm',
              canCancel: ['x'],
              props: {
                res: null,
                model: view.model[this.name].modal,
                params: {
                  title: action.name
                },
                handles: {
                  api: view.api,
                  ...inflateForm.handles(view),
                  onCreate: _this => {
                    const idKeyName = `${_this.$convertToSnakeCase(
                      view.model[this.name].model
                    )}_id`
                    view.api({
                      module: this,
                      method: 'post',
                      endPoint: action.params.flow,
                      body: {
                        form: action.params.form.slug,
                        id: action.params[idKeyName]
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.form.name = res.form.form_name

                        _this.form.buttons = res.form.buttons

                        _this.formBuilder({
                          ...res,
                          formDependency: action.params.form.slug
                        })
                      }
                    })
                  },
                  onSubmit: (_this, button) => {
                    _this.formValidation({
                      success: result => {
                        this.handles(view).setAction({
                          ...action,
                          upload: result.upload,
                          finally: () => _this.$emit('close'),
                          onCancel: () => result.onCancel(),
                          params: {
                            ...action.params,
                            ...result.payload
                          }
                        })
                      }
                    })
                  }
                }
              }
            })
            break
          case 'REGULAR_FORM':
          case 'EDIT_FORM':
            view.modal({
              component: 'DynamicForm',
              canCancel: ['x'],
              props: {
                res: null,
                model: view.model[this.name].modal,
                params: {
                  title: action.name,
                  forms: [action.params.form_slug]
                },
                hasCard: 'true',
                handles: {
                  api: view.api,
                  onCreate: _this =>
                    view.api({
                      endPoint: action.params.flow || 'authenticated-forms',
                      method: 'post',
                      body: {
                        form: action.params.form_slug,
                        id:
                          action.params.order_id || action.params.order_item_id
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.formBuilder({
                          formDependency: action.params.form_slug,
                          ...res,
                          fields: res.form
                        })
                      }
                    })
                },
                elements: [
                  {
                    component: 'b-button',
                    label: view.$t('button-close'),
                    onClick: _this => _this.$emit('close')
                  },
                  {
                    component: 'b-button',
                    label: view.$t('button-submit'),
                    class: 'is-primary',
                    onClick: _this =>
                      _this.formValidation({
                        action,
                        success: result =>
                          this.handles(view).setAction({
                            ...action,
                            upload: result.upload,
                            finally: () => _this.$emit('close'),
                            onCancel: () => result.onCancel(),
                            params: {
                              ...action.params,
                              ...result.payload
                            }
                          })
                      })
                  }
                ]
              }
            })
            break
          default:
            this.handles(view).setAction(action)
            break
        }
      },

      setAction: action => {
        return action.dialog !== false
          ? view.dialog({
              hasIcon: true,
              title: `Set to ${action.name}`,
              message: view.$t('sm-confirm-flow', {
                transition: action.name
              }),
              onCancel: () => {
                if (action.onCancel) action.onCancel()
              },
              onConfirm: () =>
                this.handles(view).setAction({ ...action, dialog: false })
            })
          : view.api({
              module: this,
              method: 'post',
              endPoint: action.endpoint,
              body: { ...action.params },
              callback: async res => {
                const container = Object.keys(res.actions)[0]
                // TODO encapsular e otimizar
                if (action.upload) {
                  let formId = null
                  if (action.params.Document.form_model_id) {
                    formId = action.params[
                      `${action.params.Document.form_model_id}`
                    ].id
                      ? action.params[`${action.params.Document.form_model_id}`]
                          .id
                      : action.params[action.params.model][
                          `${action.params.Document.form_model_id
                            .replace(
                              /[A-Z]/g,
                              letter => `_${letter.toLowerCase()}`
                            )
                            .substring(1)}_id`
                        ]
                  }

                  await action.upload(
                    action.params.Document.files,
                    action.params.Document.form_model_id
                      ? action.params.Document.form_model_id
                      : action.params.model,
                    action.params.Document.form_model_id
                      ? formId
                      : action.params[
                          `${action.params.model
                            .replace(
                              /[A-Z]/g,
                              letter => `_${letter.toLowerCase()}`
                            )
                            .substring(1)}_id`
                        ],
                    action.params.Document.type_id,
                    action.params.Document.document_type_id
                  )
                }
                if (action.finally) action.finally()
                this.handles(view).responseAction({ res, action })[container]()
              }
            })
      },

      responseAction: ({ res, action }) => ({
        set_order_item_transition: () =>
          this.handles(view)
            .responseAction({ res, action })
            .set_order_transition(),

        set_bulk_transition: () =>
          this.handles(view)
            .responseAction({ res, action })
            .set_order_transition(),

        set_order_transition: () => {
          view.params[this.name].listActions = []
          view.model[this.name].modal = {}

          view.toast({
            type: 'is-success',
            message:
              res.actions[Object.keys(res.actions)[0]].route_response.content
          })
          location.reload()
        },

        order_transition_history: () => {
          const data =
            res.actions.order_transition_history.route_response.content

          const columns = !data.form.fields
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        searchable: false,
                        class: 'list-actions',
                        content: features.filter(f =>
                          this.features.includes(f.feature)
                        )
                      }
                    : null
              }))

          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        },

        order_item_transition_history: () => {
          const data =
            res.actions.order_item_transition_history.route_response.content

          const columns = !data.form.fields
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        searchable: false,
                        class: 'list-actions',
                        content: features.filter(f =>
                          this.features.includes(f.feature)
                        )
                      }
                    : null
              }))

          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        },

        getorderinvoices: () => {
          const data = res.actions.getorderinvoices.route_response.content

          const columns = !data.form
            ? []
            : data.form.fields.map(f => ({
                name: f.field_name,
                field: f.field_name,
                label: f.field_label,
                type: f.field_type,
                id: f.id,
                render:
                  f.field_name === 'actions'
                    ? {
                        field: 'actions',
                        searchable: false,
                        class: 'list-actions',
                        content: features.filter(f =>
                          this.features.includes(f.feature)
                        )
                      }
                    : null
              }))

          view.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        }
      }),

      formButtonClick: async (_this, button) => {
        const buttonProps = JSON.parse(button.props)
        let actionCompleted = false

        if (buttonProps.aditionalParams) {
          view.params[this.name].aditionalParams = buttonProps.aditionalParams
        }

        const buttonCallback = () => {
          switch (true) {
            case button.is_submit === 1:
              if (buttonProps.redirectAfterSubmit) {
                view.params[this.name].redirectAfterSubmit =
                  buttonProps.redirectAfterSubmit
              }

              view.handles[this.name].onSubmit(_this)
              break
            case button.value === '$back':
              view.$router.back()
              break
            default:
              break
          }

          actionCompleted = true
        }

        const functionalities = {
          dialog: () => {
            return new Promise((resolve, reject) => {
              view.dialog({
                type: buttonProps.dialog.type || 'is-primary',
                hasIcon: true,
                title: view.$t(buttonProps.dialog.title),
                message: view.$t(buttonProps.dialog.message),
                onCancel: () => {},
                onConfirm: () => {
                  buttonCallback()
                  resolve()
                }
              })
            })
          }
        }

        for (const prop of Object.keys(buttonProps)) {
          if (!Object.keys(functionalities).includes(prop)) continue

          await functionalities[prop](button)
        }

        if (actionCompleted === false) buttonCallback()
      },

      openField: () => ({
        docmodal: (col, row) => view.modal()
      })
    }
  },

  elements(view) {
    return [
      {
        if: _this =>
          !_this.form.submitted && _this.isMultiStep && _this.form.step > 1,
        component: 'b-button',
        label: view.$t('button-back'),
        class: 'materials-modal-footer-buttons',
        loading: false,
        disabled: ({ previous }) => previous,
        onClick: (_this, next, previous) =>
          _this.handles.changeStep(_this, undefined, previous)
      },
      {
        if: _this =>
          !_this.form.submitted &&
          _this.isMultiStep &&
          !_this.isLastStep &&
          _this.params.forms.length,
        component: 'b-button',
        label: 'order-button-next_step',
        class: 'is-primary materials-modal-footer-buttons',
        loading: false,
        onClick: (_this, next) => _this.handles.changeStep(_this, next)
      },
      {
        if: _this =>
          !_this.form.submitted &&
          !_this.params.isModal &&
          _this.form.step === _this.params.forms.length &&
          !view.$checkFeature('uses-form-buttons') &&
          (_this.params.forms.length > 1 ||
            ['REGULAR_FORM', 'EDIT_FORM', 'MATRIX'].includes(_this.form.type)),
        component: 'b-button',
        label: view.$t('button-submit'),
        class: 'is-primary materials-modal-footer-buttons',
        loading: false,
        onClick: _this => _this.handles.onSubmit(_this)
      },
      {
        if: _this =>
          !_this.form.submitted &&
          !_this.params.isModal &&
          _this.form.step === _this.params.forms.length &&
          _this.$attrs.hasOwnProperty('editSubmitRedirect') &&
          (_this.params.forms.length > 1 ||
            ['REGULAR_FORM', 'EDIT_FORM', 'MATRIX'].includes(_this.form.type)),
        component: 'b-button',
        label: view.$t('button-submit-edit'),
        class: 'is-primary materials-modal-footer-buttons',
        loading: false,
        editSubmit: true,
        onClick: _this => _this.handles.onSubmit(_this)
      },
      {
        if: () => view.$checkFeature('order-view-transitions-top'),
        component: 'DynamicOverviewActions',
        label: view.$t('button-actions'),
        class: 'is-primary is-small rounded-icons',
        params: view.params[this.name],
        position: 'top',
        onClick: ({ row }) => view.handles[this.name].getActions(row)
      },
      {
        if: _this => {
          return (
            [
              '/returns/:key/:country/:language?',
              '/register/:key/:language?'
            ].includes(_this.$route.matched[0].path) && _this.form.step === 1
          )
        },
        component: 'ExternalSwitchLocale',
        position: 'top'
      }
    ]
  }
}
