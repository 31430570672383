export default function actions({
  view,
  row,
  usesStateMachine,
  modelSlugFeature
}) {
  const actions = []

  if (view.$checkFeature('list-invoice-popup', 'v1')) {
    actions.push({
      endpoint: '/get-order-invoices',
      name: 'order-invoices',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: 'order-invoices-list',
        sort_by: 'invoice_id.desc',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (
    view.$checkFeature('list-order-item-transition-history', 'v1') &&
    modelSlugFeature !== null &&
    modelSlugFeature === 'order-item'
  ) {
    actions.push({
      endpoint: '/order-item-transition-history',
      name: 'order-item-transition-history',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: 'order-item-transition-history-list',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (
    view.$checkFeature('list-order-transition-history', 'v1') &&
    modelSlugFeature !== null &&
    modelSlugFeature === 'order'
  ) {
    actions.push({
      endpoint: '/order-transition-history',
      name: 'order-transition-history',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: 'order-transition-history-list',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id,
        list_form: true,
        form: 'price-matrix-preview-list',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview-debug', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-debug-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        order_id: row.id,
        flow: '/order-update-insert-wf',
        form: {
          slug: 'price-matrix-preview-debug-rule',
          type: 'EDIT_FORM'
        },
        price_matrix_options: {
          frontend_test_page: true,
          from_test_page: false,
          debug: true
        },
        form_slug: 'price-matrix-preview-debug'
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview-debug-items', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-debug-feature-items',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        order_item_id: row.id,
        order_id: row.order_id,
        flow: '/order-item-update-insert-wf',
        form: {
          slug: 'price-matrix-preview-debug-rule',
          type: 'EDIT_FORM'
        },
        price_matrix_options: {
          frontend_test_page: true,
          from_test_page: false,
          debug: true
        },
        form_slug: 'price-matrix-preview-debug'
      }
    })
  }

  return actions
}
