import { useAppStore } from '@/stores/app'
import { usePageStore } from '../stores/pages'
import { useUserStore } from '@/stores/user'
import { useMenuStore } from '@/stores/menus'
import { i18n, loadLanguageAsync } from '@/plugins/i18n'
import { generic, getClientLogo } from '@/services/utilities'
import { resetRouter } from '@/router'

const loadSteps = route => {
  if (!route.params.language) {
    i18n.locale = null
  }

  const params = {
    country: route.params.country,
    locale: route.params.language
  }

  return generic('blind-return-flow', params)
}

export default async function blindReturns(to, from, next) {
  const appStore = useAppStore()
  const pageStore = usePageStore()
  const userStore = useUserStore()
  const menusStore = useMenuStore()

  userStore.logout()
  appStore.$reset()
  menusStore.$reset()
  pageStore.resetPages()

  await resetRouter

  appStore.$patch({
    clientKey: to.params.key
  })

  const clientLogoRes = await getClientLogo(to.params.key)

  appStore.$patch({
    languages: clientLogoRes.data.languages
  })

  const steps = await loadSteps(to)

  const language = to.params.language || steps[1].default_language_id

  appStore.$patch({
    selectedLanguage: language
  })

  userStore.$patch({
    language
  })

  i18n.locale = language

  loadLanguageAsync(language, true)

  const forms = Object.values(steps).map(step => ({
    name: step.form_name,
    slug: step.form,
    submit: parseInt(step.sequence) === 3,
    type: step.form_type
  }))

  pageStore.$patch({
    page: {
      label: 'Blind Returns',
      topLogo: clientLogoRes.data.logo,
      hideHerobar: true,
      classes: {
        container: 'blind-returns-backgroud',
        sectionContainer: 'blind-returns-container'
      },
      to: '/returns/:key/:country/:language?',
      title: 'Blind Returns',
      menu: {},
      modules: [
        {
          type: 'form',
          flow: 'new-company-form',
          submit_flow: 'return-order-insert',
          props:
            '{"model": {}, "title": null, "endPoints": {}, "hasCard": "true"}',
          forms
        }
      ],
      tabs: [],
      buttons: [],
      features: [],
      notifications: []
    }
  })

  next()
}
