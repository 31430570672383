import { downloadFileNew } from '@/services/document'

export const features = [
  {
    if: ({ handles, model, params, _this }) => true,
    feature: 'hellow-world',
    sequency: 1,
    component: 'b-button',
    content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
    class: 'is-primary',
    onClick: () => alert('Hello World!')
  },
  {
    if: ({ _this }) => _this.$checkFeature('order-list-actions-view', 'v1'),
    feature: 'order-list-actions-view',
    sequency: 1,
    component: 'router-link',
    to: `/orders/view/:id`,
    content: { element: 'b-icon', title: 'View', icon: 'eye' },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    feature: 'order-item-list-actions-view',
    sequency: 1,
    component: 'router-link',
    to: '/orders/view/:order_id',
    content: { element: 'b-icon', title: 'View', icon: 'eye' },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    feature: 'order-list-actions-edit',
    sequency: 1,
    component: 'router-link',
    to: '/orders/edit/:id',
    content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    feature: 'uses-complaints-list',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Order Item Complaints List',
      icon: 'comment-remove'
    },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) => {
      _this.$parent.api({
        module: this,
        method: 'post',
        endPoint: 'list-order-items-complaints',
        body: {
          form: 'list-order-items-complaints',
          order_item_id: row.id || row.order_item_id,
          list_form: true
        },
        callback: async res => {
          const data =
            res.actions.get_all_order_item_complaints.route_response.content

          const columns = data.form.fields.map(f => ({
            name: f.field_name,
            field: f.field_name,
            label: f.field_label,
            type: f.field_type,
            id: f.id,
            render:
              f.field_name === 'actions'
                ? {
                    field: 'actions',
                    searchable: false,
                    class: 'list-actions'
                  }
                : null
          }))

          _this.$parent.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        }
      })
    }
  },
  {
    if: () => true,
    feature: 'order-item-details-list',
    sequency: 2,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Order Item Details List',
      icon: 'playlist-plus'
    },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) => {
      _this.$parent.api({
        module: this,
        method: 'post',
        endPoint: 'list-form-order-item-details',
        body: {
          form: 'list-form-order-item-details',
          order_item_id: row.id || row.order_item_id,
          list_form: true
        },
        callback: async res => {
          const data = res.actions.get_all_details.route_response.content

          const columns = data.form.fields.map(f => ({
            name: f.field_name,
            field: f.field_name,
            label: f.field_label,
            type: f.field_type,
            id: f.id,
            render:
              f.field_name === 'actions'
                ? {
                    field: 'actions',
                    searchable: false,
                    class: 'list-actions'
                  }
                : null
          }))

          _this.$parent.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        }
      })
    }
  },
  {
    if: () => true,
    feature: 'order-invoices-list',
    sequency: 1,
    component: 'b-button',
    content: { element: 'b-icon', title: 'Download', icon: 'download' },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) =>
      downloadFileNew(
        `invoicing/document-builder/download/${row.document_builder_id}`
      ).then(response => {
        _this.isLoading = false

        if (response.status === 'error')
          return _this.$buefy.toast.open({
            message: _this.$t('invoicing-download_error'),
            position: 'is-top',
            type: 'is-danger',
            duration: 3000
          })

        const url = window.URL.createObjectURL(response)
        const a = document.createElement('a')

        a.href = url
        a.download = `${row.document_builder_id}.pdf`
        a.click()

        window.URL.revokeObjectURL(url)
      })
  }
]
