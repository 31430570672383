import { useUserStore } from '../../stores/user'
import { updateDetails } from '@/services/authentication'
import { api } from '@/services/api'
import { getGenericData } from '@/services/document'

export default function elements({ view }) {
  return [
    {
      if: () => true,
      routes: ['/companies'],
      sequency: 1,
      component: 'router-link',
      to: `/companies/:id`,
      content: { element: 'b-icon', title: 'View', icon: 'eye' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/asynchronous-requests'],
      sequency: 1,
      render: ({ _this, row }) => {
        let disabled = true
        if (row.results !== null && row.results !== undefined) {
          disabled = JSON.parse(row.results).length === 0
        }

        return {
          component: 'b-button',
          content: { element: 'b-icon', title: 'View', icon: 'eye' },
          class: 'is-primary is-small rounded-icons',
          outlined: true,
          disabled
        }
      },

      onClick: ({ _this, row }) => {
        if (row.results === null || row.results === undefined) {
          return
        }

        const itemsDetail = JSON.parse(row.results)

        if (itemsDetail.length === 0) {
          return
        }

        view.modal({
          component: 'DynamicList',
          props: {
            params: {
              paginated: false,
              filterable: false,
              title: `Detail ${_this.params.title}`,
              data: itemsDetail,
              columns: [
                {
                  name: 'name',
                  field: 'name',
                  label: view.$t('asynchronous-requests-name'),
                  type: 'text'
                },
                {
                  name: 'status',
                  field: 'status',
                  label: view.$t('asynchronous-requests-status'),
                  type: 'text'
                },
                {
                  name: 'date',
                  field: 'date',
                  label: view.$t('asynchronous-requests-date'),
                  type: 'date'
                },
                {
                  name: 'message',
                  field: 'message',
                  label: view.$t('asynchronous-requests-message'),
                  type: 'text'
                }
              ]
            },
            model: {}
          }
        })
      }
    },
    {
      if: () => true,
      routes: ['/companies'],
      sequency: 2,
      component: 'router-link',
      to: `/companies/edit/:id`,
      content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/abs/periods'],
      sequency: 1,
      component: 'router-link',
      to: `/abs/edit/:id`,
      content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) => !row.deleted_at,
      routes: ['/abs/periods'],
      sequency: 2,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: view.modules.list.props.endPoints.delete,
          params: { id: row.id }
        })
    },
    {
      if: () => true,
      routes: ['/users'],
      sequency: 1,
      component: 'router-link',
      to: `/users/view/:id`,
      content: { element: 'b-icon', title: 'View', icon: 'eye' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/users'],
      sequency: 2,
      component: 'router-link',
      to: `/users/edit/:id`,
      content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => {
        const userStore = useUserStore()
        return (
          userStore.isImpersonating === false &&
          userStore.hasAbility('user-impersonate')
        )
      },
      routes: ['/users'],
      sequency: 3,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Impersonate',
        icon: 'account-switch'
      },
      class: 'button is-primary is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) => {
        _this.handles.setAction({
          dialog: true,
          refresh: false,
          endpoint: '/impersonate',
          params: {
            user_id: row.id
          },
          finally: async res => {
            const userStore = useUserStore()
            userStore.impseronate({
              id: row.id,
              name: row.name,
              token: res.accessToken
            })

            await updateDetails()
            _this.$router.push('/')
          },
          title: view.$t('user-impersonate-dialog-title'),
          message: view.$t('user-impersonate-dialog-message')
        })
      }
    },
    {
      if: () => true,
      routes: ['/periods'],
      sequency: 1,
      component: 'router-link',
      to: `/periods/view/:id`,
      content: { element: 'b-icon', title: 'View', icon: 'eye' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/periods'],
      sequency: 1,
      component: 'router-link',
      to: `/periods/edit/:id`,
      content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) =>
        view.modules.list.props.endPoints &&
        view.modules.list.props.endPoints.show,
      routes: ['/routing/matrix'],
      sequency: 1,
      component: 'router-link',
      to: view.modules.list.props.endPoints.show,
      content: {
        element: 'b-icon',
        title: 'Show',
        icon: 'eye'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/routing/matrix'],
      sequency: 1,
      component: 'router-link',
      to: `/routing/matrix/duplicate/:id`,
      content: {
        element: 'b-icon',
        title: 'Duplicate',
        icon: 'arrange-bring-forward'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) => !row.deleted_at,
      routes: ['/routing/matrix'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: view.modules.list.props.endPoints.delete,
          params: { id: row.id }
        })
    },
    {
      if: ({ row }) =>
        view.modules.list.props.endPoints &&
        view.modules.list.props.endPoints.show,
      routes: ['/price/matrix'],
      sequency: 1,
      component: 'router-link',
      to: view.modules.list.props.endPoints.show,
      content: {
        element: 'b-icon',
        title: 'Show',
        icon: 'eye'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/price/matrix'],
      sequency: 1,
      component: 'router-link',
      to: `/price/matrix/duplicate/:id`,
      content: {
        element: 'b-icon',
        title: 'Duplicate',
        icon: 'arrange-bring-forward'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) => !row.deleted_at,
      routes: ['/price/matrix'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) =>
        _this.handles.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: view.modules.list.props.endPoints.delete,
          params: { id: row.id }
        })
    },
    {
      if: ({ row }) =>
        view.modules.list.props.endPoints &&
        view.modules.list.props.endPoints.show,
      routes: ['/sm-flow/matrix'],
      sequency: 1,
      component: 'router-link',
      to: view.modules.list.props.endPoints.show,
      content: {
        element: 'b-icon',
        title: 'Show',
        icon: 'eye'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/sm-flow/matrix'],
      sequency: 1,
      component: 'router-link',
      to: `/sm-flow/matrix/duplicate/:id`,
      content: {
        element: 'b-icon',
        title: 'Duplicate',
        icon: 'arrange-bring-forward'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) => !row.deleted_at,
      routes: ['/sm-flow/matrix'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) =>
        _this.handles.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: view.modules.list.props.endPoints.delete,
          params: { id: row.id }
        })
    },
    {
      if: () => true,
      routes: ['/canned-reports'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Generate',
        icon: 'file-download'
      },
      class: 'is-primary is-small rounded-icons',
      onClick: ({ _this, row }) =>
        row.form_definition
          ? view.modal({
              component: 'DynamicForm',
              props: {
                res: null,
                model: view.model.list.modal,
                params: {
                  title: row.title,
                  forms: [row.form_definition.slug]
                },
                hasCard: 'true',
                handles: {
                  api: view.api,
                  onCreate: _this =>
                    view.api({
                      endPoint: 'authenticated-forms',
                      method: 'post',
                      body: {
                        form: row.form_definition.slug
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.formBuilder({
                          formDependency: row.form_definition.slug,
                          ...res,
                          fields: res.fields
                        })
                      }
                    })
                },
                elements: [
                  {
                    component: 'b-button',
                    label: view.$t('button-close'),
                    onClick: _this => _this.$emit('close')
                  },
                  {
                    component: 'b-button',
                    label: view.$t('button-submit'),
                    class: 'is-primary',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        success: result =>
                          view.handles.list.setAction({
                            dialog: true,
                            download: true,
                            endpoint:
                              view.modules.list.props.endPoints.calculate,
                            finally: () => _this.$emit('close'),
                            params: {
                              ...row,
                              ...result.payload
                            }
                          })
                      })
                  }
                ]
              }
            })
          : view.handles.list.setAction({
              dialog: true,
              endpoint: view.modules.list.props.endPoints.download,
              params: row,
              download: true
            })
    },
    {
      if: () => true,
      routes: ['/canned-report-templates'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Generate',
        icon: 'file-download'
      },
      class: 'is-primary is-small rounded-icons',

      onClick: ({ _this, row }) =>
        row.form
          ? view.modal({
              component: 'DynamicForm',
              props: {
                res: null,
                model: view.model.list.modal,
                params: {
                  title: row.title,
                  forms: [row.form.slug]
                },
                hasCard: 'true',
                handles: {
                  api: view.api,
                  onCreate: _this =>
                    view.api({
                      endPoint: 'authenticated-forms',
                      method: 'post',
                      body: {
                        form: row.form
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.formBuilder({
                          formDependency: row.form,
                          ...res,
                          fields: res.fields
                        })
                      }
                    })
                },
                elements: [
                  {
                    component: 'b-button',
                    label: view.$t('button-close'),
                    onClick: _this => _this.$emit('close')
                  },
                  {
                    component: 'b-button',
                    label: view.$t('canned-report-generate-button'),
                    class: 'is-primary',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        success: result => {
                          return view.handles.list.setAction({
                            dialog: false,
                            endpoint: `canned_report_templates/${row.id}/export`,
                            finally: () => _this.$emit('close'),
                            params: result.payload
                          })
                        }
                      })
                  }
                ]
              }
            })
          : view.handles.list.setAction({
              dialog: false,
              endpoint: `canned_report_templates/${row.id}/export`,
              finally: () => _this.$emit('close'),
              params: {}
            })
    },
    {
      if: ({ row }) =>
        [3, 6, 7, 30, 40].includes(row.invoice_preview_status_id),
      routes: ['/invoice-previews'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('download-preview'),
        icon: 'file-download'
      },
      class: 'is-primary is-small rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: false,
          endpoint: view.modules.list.props.endPoints.previewDownload,
          params: row,
          download: true,
          title: view.$t('invoice-download-preview-filename')
        })
    },
    {
      if: ({ row }) =>
        [3, 4, 6, 7, 30, 40].includes(row.invoice_preview_status_id),
      routes: ['/invoice-previews'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('order-items-not-invoiced-export'),
        icon: 'currency-usd-off'
      },
      class: 'is-primary is-small rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: false,
          endpoint: view.modules.list.props.endPoints.noPriceDownload,
          params: row,
          download: true,
          title: view.$t('invoice-download-no-price-filename')
        })
    },
    {
      if: ({ row }) => [3].includes(row.invoice_preview_status_id),
      routes: ['/invoice-previews'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('invoicing-generate_button'),
        icon: 'currency-usd'
      },
      class: 'is-primary is-small rounded-icons',
      onClick: ({ _this, row }) =>
        _this.handles.setAction({
          dialog: true,
          endpoint: view.modules.list.props.endPoints.generate,
          params: row,
          title: view.$t('invoicing-generate_button'),
          message: view.$t('invoicing-generate_confirm_message')
        })
    },
    {
      if: ({ row }) => [3].includes(row.invoice_preview_status_id),
      routes: ['/invoice-previews'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('invoicing-prevent_generate'),
        icon: 'file-lock'
      },
      class: 'is-danger is-small rounded-icons',
      onClick: ({ _this, row }) =>
        _this.handles.setAction({
          dialog: true,
          endpoint: view.modules.list.props.endPoints.disableGenerate,
          params: row,
          title: ' ',
          message: view.$t('invoice-preview-prevent_confirm_message')
        })
    },
    {
      if: ({ row }) =>
        view.modules.list.props.endPoints &&
        view.modules.list.props.endPoints.show,
      routes: ['/contract/matrix'],
      sequency: 1,
      component: 'router-link',
      to: view.modules.list.props.endPoints.show,
      content: {
        element: 'b-icon',
        title: 'Show',
        icon: 'eye'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/contract/matrix'],
      sequency: 1,
      component: 'router-link',
      to: `/contract/matrix/duplicate/:id`,
      content: {
        element: 'b-icon',
        title: 'Duplicate',
        icon: 'arrange-bring-forward'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) => !row.deleted_at,
      routes: ['/contract/matrix/declarations', '/contract/matrix'],
      sequency: 2,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) =>
        _this.handles.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: view.modules.list.props.endPoints.delete,
          params: { id: row.id }
        })
    },
    {
      if: () => true,
      routes: ['/protocols'],
      sequency: 1,
      component: 'router-link',
      to: `/protocols/view/:id`,
      content: {
        element: 'b-icon',
        title: 'View',
        icon: 'eye'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      routes: ['/protocols'],
      sequency: 1,
      component: 'router-link',
      to: `/protocols/edit/:id`,
      content: {
        element: 'b-icon',
        title: 'Edit',
        icon: 'pencil'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    // {
    //   if: ({ row }) => row.id,
    //   routes: ['/declarations'],
    //   sequency: 1,
    //   component: 'b-button',
    //   content: {
    //     element: 'b-icon',
    //     title: 'View',
    //     icon: 'eye'
    //   },
    //   class: 'is-primary is-small rounded-icons',
    //   outlined: true,
    //   onClick: ({ _this, row }) => {
    //     _this.$router.push(
    //       `/declarations/new/${row.period_id}/${row.company_id}`
    //     )
    //   }
    // },
    {
      if: ({ row }) => row.period_id && row.company_id,
      routes: ['/declarations-pending-list'],
      sequency: 1,
      component: 'router-link',
      to: `/declarations/new/:period_id/:company_id`,
      content: {
        element: 'b-icon',
        title: 'Create Declaration',
        icon: 'calendar'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: ({ row }) => true,
      routes: ['/invoiced-documents'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('invoice-download'),
        icon: 'file-download'
      },
      class: 'is-primary is-small rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: false,
          endpoint: view.modules.list.props.endPoints.download.replace(
            ':key',
            row.document_builder_id
          ),
          params: row,
          download: true,
          title: view.$t('download')
        })
    },
    {
      if: ({ row }) =>
        view.modules.list.props.endPoints &&
        view.modules.list.props.endPoints.sendEmail,
      routes: ['/invoiced-documents'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('invoice-email-send'),
        icon: 'email-outline'
      },
      class: 'is-primary is-small rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: true,
          endpoint: view.modules.list.props.endPoints.sendEmail.replace(
            ':key',
            row.document_builder_id
          ),
          params: row,
          download: false,
          title: view.$t('send-email'),
          message: view.$t('send-email-question')
        })
    },
    {
      if: ({ row }) =>
        !row.deleted_at &&
        row.frontend_notification_type_name !== 'Bell Notification',
      routes: ['/notifications'],
      sequency: 2,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small rounded-icons is-outlined',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: '/notifications/delete',
          params: { id: row.id }
        })
    },
    {
      if: () => true,
      routes: ['/chunk-model-upload-list'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: view.$t('detail'),
        icon: 'arrange-bring-forward'
      },
      class: 'button is-primary is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) => {
        if (row.fileList === null || row.fileList === undefined) {
          return
        }

        const data = {
          hash: row.hash
        }

        getGenericData('chunk-model-upload/detail', data).then(response => {
          const itemsDetail = response.data

          if (itemsDetail.length === 0) {
            return
          }

          view.modal({
            component: 'DynamicList',
            props: {
              params: {
                paginated: false,
                filterable: false,
                title: `Detail ${_this.params.title}`,
                data: itemsDetail,
                listTopButton: true,
                columns: [
                  {
                    name: 'File Name',
                    field: 'fileName',
                    label: view.$t('chunk_upload_file_name'),
                    type: 'text'
                  },
                  {
                    name: 'Status',
                    field: 'statusText',
                    label: view.$t('status'),
                    type: 'date'
                  },
                  {
                    name: 'actions',
                    field: 'actions',
                    label: view.$t('actions'),
                    type: 'text',
                    render: {
                      field: 'actions',
                      content: [
                        {
                          if: ({ _this, row }) =>
                            row.status !== 1 && row.status !== 3,
                          sequency: 1,
                          component: 'b-button',
                          content: {
                            element: 'b-icon',
                            title: view.$t('chunk_upload_ignore_title'),
                            icon: 'cancel'
                          },
                          class: 'is-primary is-small rounded-icons is-danger',
                          onClick: ({ row }) => {
                            view.dialog({
                              type: 'is-danger',
                              hasIcon: true,
                              title: view.$t('chunk_upload_ignore_title'),
                              message: view.$t('chunk_upload_ignore_question'),
                              onCancel: () => {},
                              onConfirm: () => {
                                const originalStatus = row.status
                                const data = {
                                  fileName: row.fileName,
                                  filePath: row.filePath,
                                  model: row.Model,
                                  modelId: row.ModelId
                                }

                                row.status = 3

                                const apiResponse = api
                                  .post('chunk-model-upload/remove', data)
                                  .then(response => {
                                    if (response.code === 200) {
                                      row.statusText = view.$t(
                                        'chunk_upload_status_ignored'
                                      )
                                      return
                                    }

                                    row.status = originalStatus
                                  })

                                return apiResponse
                              }
                            })
                          }
                        },
                        {
                          if: ({ _this, row }) => row.status === 0,
                          sequency: 2,
                          component: 'b-button',
                          content: {
                            element: 'b-icon',
                            title: view.$t('chunk_upload_save_title'),
                            icon: 'check'
                          },
                          class: 'is-primary is-small rounded-icons is-success',
                          onClick: ({ _this, row }) => {
                            view.dialog({
                              type: 'is-info',
                              hasIcon: true,
                              title: view.$t('chunk_upload_save_title'),
                              message: view.$t('chunk_upload_save_question'),
                              onCancel: () => {},
                              onConfirm: () => {
                                const data = {
                                  fileName: row.fileName,
                                  filePath: row.filePath,
                                  model: row.Model,
                                  modelId: row.ModelId
                                }

                                row.status = 1

                                const apiResponse = api
                                  .post('chunk-model-upload/aprove', data)
                                  .then(response => {
                                    if (response.code === 200) {
                                      row.statusText = view.$t(
                                        'chunk_upload_status_confirmed'
                                      )
                                      return
                                    }

                                    row.status = 0
                                  })

                                return apiResponse
                              }
                            })
                          }
                        }
                      ]
                    }
                  }
                ]
              },
              elements: [
                {
                  component: 'b-button',
                  label: view.$t('aprove_all'),
                  position: 'top',
                  class: 'custom-table-cell is-small is-success',
                  onClick: _this => {
                    view.dialog({
                      type: 'is-info',
                      hasIcon: true,
                      title: view.$t('chunk_upload_save_title'),
                      message: view.$t('chunk_upload_save_question'),
                      onCancel: () => {},
                      onConfirm: () => {
                        const filesToApprove = _this.params.data.filter(
                          element => element.status === 0
                        )
                        const fileList = {
                          fileList: { ...filesToApprove }
                        }

                        const apiResponse = api
                          .post('chunk-model-upload/bulk-aprove', fileList)
                          .then(response => {
                            if (response.code === 200) {
                              _this.params.data
                                .filter(element => element.status === 0)
                                .forEach(row => {
                                  row.status = 1
                                  row.statusText = view.$t(
                                    'chunk_upload_status_confirmed'
                                  )
                                })
                              return
                            }

                            _this.params.data
                              .filter(element => element.status === 0)
                              .forEach(row => {
                                row.status = 0
                              })
                          })
                          .catch(error => {
                            console.log(error)
                          })

                        return apiResponse
                      }
                    })
                  }
                }
              ],
              model: {}
            }
          })
        })
      }
    }
  ].filter(element => element.routes.includes(view.$route.path))
}
